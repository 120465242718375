import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../../constants/colors';
import style from '../../constants/style';
import { AppContext, IContext } from '../../store/app-provider';
import MemeTxt from "../meme-txt";
import MemeImg from "../meme-img";

interface State {

}

interface Props {
  owner: any
  style?: ViewStyle
}

class TwoTextOverlapOneImage extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  render() {

    return (
      <View style={[style.fullSize]}>
        <MemeTxt textProp='text1'
                 onHeightChange={(h: number) => {
                 }}
                 textStyle={[style.fullWidth, styles.shadow, {
                   fontSize: 20,
                   textAlign: 'center',
                   color: "#ffffff"
                 }]}
                 style={{
                   width: '100%',
                   padding: 5,
                   top: 0,
                   zIndex: 999999,
                   position: "absolute",
                   justifyContent: 'center',
                   backgroundColor: 'transparent'
                 }}></MemeTxt>
        <MemeImg setImageSize={this.props.owner.setImageSize.bind(this)} style={style.fullWidth}></MemeImg>
        <MemeTxt textProp='text2'
                 onHeightChange={(h: number) => {
                 }}
                 textStyle={[style.fullWidth, styles.shadow, {
                   fontSize: 20,
                   textAlign: 'center',
                   color: "#ffffff"
                 }]}
                 style={{
                   width: '100%',
                   padding: 5,
                   bottom: 0,
                   zIndex: 999999,
                   position: "absolute",
                   justifyContent: 'center',
                   backgroundColor: 'transparent'
                 }}></MemeTxt>
      </View>
    )
  };

}

const styles = StyleSheet.create({
  shadow: {
    color: '#fff',
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 1,
    textShadowColor: '#000',
  },
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default TwoTextOverlapOneImage;
