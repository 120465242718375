import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Dimensions } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { IMeme } from '../models/meme';
import { AppContext, IContext } from '../store/app-provider';

const { Provider, Consumer } = React.createContext({ imageWidth: 0, imageHeight: 0 });

interface State {
  layoutWidth: number
  layoutHeight: number,
  canvasWidth?: number,
  canvasHeight?: number,
}

interface Props {
  layoutWidth: number
  layoutHeight: number,
  style?: ViewStyle
  layout?: string
}

class MemeCanvas extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  frame: any
  canvas: any
  layout: any

  constructor(props: any) {
    super(props);
    this.state = { layoutWidth: 1024, layoutHeight: 1024 }

  }

  componentDidMount() {
    /*  this.sizeLayout({
         height: 694,
         left: 10,
         top: 80,
         width: 370,
         x: 0,
         y: 0
     }) */
  }

  onLayout(event: any) {
    this.layout = event.nativeEvent.layout;
    this.sizeLayout(event.nativeEvent.layout)
  }

  componentDidUpdate(prevProps: any) {

    if (this.props.layoutWidth !== prevProps.layoutWidth) {
      this.sizeLayout(this.layout)
    }
  }

  sizeLayout(layout: any) {

    const { x, y, height, width } = layout;
    const imageWidth = (this.props.layoutWidth || this.state.layoutWidth);
    const imageHeight = (this.props.layoutHeight || this.state.layoutHeight);
    const scaleFull = Math.min(
      width / imageWidth,
      height / imageHeight
    );
    console.log("layout", scaleFull);
    let newWidth = 100;
    let newHeight = 100;

    const vr = imageWidth / imageHeight;
    const pr = height / width;
    if (pr > vr) {

      if (imageHeight > imageWidth) {
        console.log("3")
        newWidth = width;
        newHeight = height * vr;
      } else {
        console.log("4")
        newWidth = width;
        newHeight = imageHeight * scaleFull;
      }

    } else {
      if (imageHeight > imageWidth) {
        console.log("5")
        newWidth = imageWidth * scaleFull;
        newHeight = height;
      } else {
        console.log("6")
        newWidth = width * pr;
        newHeight = height;
      }
    }

    this.setState({ canvasWidth: newWidth, canvasHeight: newHeight })
  }

  render() {

    return (
      <View onLayout={this.onLayout.bind(this)} ref={this.frame} style={{

        width: '100%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <View style={[{
          borderWidth: 0,
          borderColor: colors.primaryColor,
          width: this.state.canvasWidth,
          height: this.state.canvasHeight
        }]} ref={this.canvas}>
          {this.props.children}

        </View>
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default MemeCanvas;
