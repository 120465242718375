/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      isPremium
      memeNumCreatedCount
      email
      reports
      picture
      firstName
      lastName
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isPremium
        memeNumCreatedCount
        email
        reports
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserMeme = /* GraphQL */ `
  query GetUserMeme($id: ID!) {
    getUserMeme(id: $id) {
      id
      userId
      user {
        id
        isPremium
        memeNumCreatedCount
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeImage
      type
      title
      memeConfig
      numLikes
      numComments
      savedAt
      createdAt
      updatedAt
    }
  }
`;
export const listUserMemes = /* GraphQL */ `
  query ListUserMemes(
    $filter: ModelUserMemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMemes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          isPremium
          memeNumCreatedCount
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
        }
        memeImage
        type
        title
        memeConfig
        numLikes
        numComments
        savedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPostsByTimeAndUser = /* GraphQL */ `
  query GetPostsByTimeAndUser(
    $userId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByTimeAndUser(
      userId: $userId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          memeNumCreatedCount
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
        }
        memeImage
        type
        title
        memeConfig
        numLikes
        numComments
        savedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPostsByTime = /* GraphQL */ `
  query GetPostsByTime(
    $type: PostType!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByTime(
      type: $type
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          memeNumCreatedCount
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
        }
        memeImage
        type
        title
        memeConfig
        numLikes
        numComments
        savedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserMemeLikes = /* GraphQL */ `
  query GetUserMemeLikes($id: ID!) {
    getUserMemeLikes(id: $id) {
      id
      userId
      user {
        id
        isPremium
        memeNumCreatedCount
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeId
      meme {
        id
        userId
        user {
          id
          isPremium
          memeNumCreatedCount
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
        }
        memeImage
        type
        title
        memeConfig
        numLikes
        numComments
        savedAt
        createdAt
        updatedAt
      }
      savedAt
      createdAt
      updatedAt
    }
  }
`;
export const listUserMemeLikes = /* GraphQL */ `
  query ListUserMemeLikes(
    $filter: ModelUserMemeLikesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMemeLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          isPremium
          memeNumCreatedCount
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
        }
        memeId
        meme {
          id
          userId
          memeImage
          type
          title
          memeConfig
          numLikes
          numComments
          savedAt
          createdAt
          updatedAt
        }
        savedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLikesByTimeAndUser = /* GraphQL */ `
  query GetLikesByTimeAndUser(
    $userId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMemeLikesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLikesByTimeAndUser(
      userId: $userId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          memeNumCreatedCount
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
        }
        memeId
        meme {
          id
          userId
          user {
            id
            isPremium
            memeNumCreatedCount
            email
            picture
            firstName
            lastName
            createdAt
            updatedAt
          }
          memeImage
          type
          title
          memeConfig
          numLikes
          numComments
          savedAt
          createdAt
          updatedAt
        }
        savedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserMemeComments = /* GraphQL */ `
  query GetUserMemeComments($id: ID!) {
    getUserMemeComments(id: $id) {
      id
      userId
      comment
      user {
        id
        isPremium
        memeNumCreatedCount
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeId
      savedAt
      createdAt
      updatedAt
    }
  }
`;
export const listUserMemeComments = /* GraphQL */ `
  query ListUserMemeComments(
    $filter: ModelUserMemeCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMemeComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        comment
        user {
          id
          isPremium
          memeNumCreatedCount
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
        }
        memeId
        savedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommentsByMemeAndTime = /* GraphQL */ `
  query GetCommentsByMemeAndTime(
    $memeId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMemeCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentsByMemeAndTime(
      memeId: $memeId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        comment
        user {
          id
          isPremium
          memeNumCreatedCount
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
        }
        memeId
        savedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
