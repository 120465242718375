import * as React from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import Meme from '../components/meme';
import MemeButtonBar from '../components/meme-button-bar';

interface Props {

}

interface State {

}

class BuilderScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  meme: any;

  constructor(props: any) {
    super(props);

  }

  async componentDidMount() {

  }

  componentDidUpdate(prevPops: any) {

  }

  onAction(action: string) {
    switch (action) {
      case "Save":
        this.meme.saveMeme();
        break;
      case "Export":
        this.meme.export();
        break;
      case "Share":
        this.meme.share();
        break;
    }

  }

  render() {

    const busyIndicator = () => {
      /* if (this.props.app.isBusy) {
          return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
      } */
    }
    const appBusyIndicator = () => {
      if (this.context.state.isBusy) {
        return <ActivityIndicator style={[style.busyIndicator]} size="large" color={colors.primaryColor}/>
      }
    }
    return (

      <View style={style.page}>
        <View style={[style.container, { maxWidth: undefined }]}>

          <View style={[style.fullWidth, { flex: 1, flexDirection: 'column', padding: 0 }]}>
            <Meme ref={ref => this.meme = ref} style={{ width: '100%', height: '100%', padding: 0 }}></Meme>
          </View>
          <MemeButtonBar onAction={this.onAction.bind(this)} style={{
            width: '100%',
            marginTop: 10,
            maxWidth: 500
          }}></MemeButtonBar>
        </View>
        {appBusyIndicator()}

      </View>

    )
  };

}

export default (withTranslation()(BuilderScreen));

const styles = StyleSheet.create({
  input: {
    padding: 5,
    height: 35,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: colors.textColor,
    color: colors.textColor
  },
  screenItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 10,
    width: '100%',
    height: '100%',
    backgroundColor: colors.textColor
  }
});
