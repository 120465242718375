import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../../constants/colors';
import style from '../../constants/style';
import { AppContext, IContext } from '../../store/app-provider';
import MemeTxt from "../meme-txt";
import MemeImg from "../meme-img";

interface State {

}

interface Props {
  owner: any
  style?: ViewStyle
}

class OneTextOverlapOneImage extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  render() {

    return (
      <View style={[style.fullSize]}>
        <MemeTxt textProp='text1' onHeightChange={(h: number) => {
        }} style={{
          width: '100%',
          padding: 5,
          top: 0,
          zIndex: 999999,
          position: "absolute",
          justifyContent: 'center',
          backgroundColor: 'transparent'
        }}></MemeTxt>
        <MemeImg setImageSize={this.props.owner.setImageSize.bind(this)} style={style.fullWidth}></MemeImg>

      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default OneTextOverlapOneImage;
