/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9ebd178a-f09c-4afa-8966-eb9b8d33cab6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PnEVFA0QV",
    "aws_user_pools_web_client_id": "2uu4nr844bv130kla78r2mq159",
    "oauth": {
        "domain": "imagenie-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/,imagenie://,https://www.imagenie.io/",
        "redirectSignOut": "http://localhost:19006/,imagenie://,https://www.imagenie.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PICTURE"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "imagenierestapi",
            "endpoint": "https://ggfj9ecz5b.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://l4kc625vyvg75cmrl3baswdrfy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7bgmcgri25aadbkb6hx5rtx7aa",
    "aws_user_files_s3_bucket": "imagenie141955-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "550240a4f5e042c6a0bb47848875353a",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
