import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  TextInput,
  ActivityIndicator,
  Platform, Keyboard
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { IMeme } from '../models/meme';

import { AppContext, IContext } from '../store/app-provider';
import MemeButtonBar from './meme-button-bar';
import MemeCanvas from './meme-canvas';
import MemeImg from './meme-img';
import MemeTxt from './meme-txt';
import ViewShot from "react-native-view-shot";
import { captureRef } from "react-native-view-shot";
import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from 'uuid';
import TwoTextOneImage from "./templates/TwoTextOneImage";
import TextAboveOneImage from "./templates/TextAboveOneImage";
import OneTextOverlapOneImage from "./templates/OneTextOverlapOneImage";
import TwoTextOverlapOneImage from "./templates/TwoTextOverlapOneImage";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import { FontAwesome5, SimpleLineIcons } from "@expo/vector-icons";
import PrimaryButton from "./primary-button";
//import CameraRoll from "@react-native-community/cameraroll";
let CameraModule: any;
if (Platform.OS !== "web") {
  CameraModule = require("@react-native-community/cameraroll");
}

interface State {
  isBusy: boolean,
  canvasWidth: number,
  canvasHeight: number,
}

interface Props {
  style: ViewStyle
}

class Meme extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  capture: any;

  constructor(props: any) {
    super(props);
    this.state = { canvasWidth: 0, canvasHeight: 0, isBusy: false }
  }

  saveMeme() {
    console.log('saveMeme', this.context.state.viewMeme);
    /*if (!this.context.state.viewMeme) {
      SheetManager.show(`save_sheet`);
    } else {
      this.saveMemeToCloud();
    }*/
    SheetManager.show(`save_sheet`);

  }

  export() {
    /* if (!this.context.state.viewMeme) {
       SheetManager.show(`save_sheet`);
     } else {
       this.saveMemeToDevice();
     }*/
    this.saveMemeToDevice();

  }

  share() {
    if (!this.context.state.viewMeme) {
      this.context.showToast('Please save your new meme first!', 'error');
      return;
    }
    this.context.shareMeme(this.context.state.viewMeme);
  }

  async saveMemeToDevice() {
    this.setState({ isBusy: true })
    await SheetManager.hideAll();
    const uri = await captureRef(this.capture, {
      format: 'png',
      quality: 0.8,
    });

    /*if (Platform.OS === 'android') {
      const granted = await getPermissionAndroid();
      if (!granted) {
        return;
      }
    }*/

    // cameraroll saves image

    await CameraModule?.saveToCameraRoll(uri, 'photo')
    this.context.showToast('Meme saved to device!', 'success');
    this.setState({ isBusy: false })

  }

  componentDidMount() {

  }

  setImageSize(width: number, height: number) {
    console.log('setImageSize', width, height);
    this.setState({ canvasWidth: width, canvasHeight: height })
  }

  setMemeTitle(title: string) {
    let meme = { ...this.context.state.currentMeme };
    meme.title = title;
    this.context.setCurrentMeme(meme);
  }

  async savePreview(id: string) {
    if (!this.context.state.currentMeme?.title) {
      this.context.showToast('Please enter a title!', 'error');
      return;
    }
    if (!this.context.state.currentMeme?.imageUrl) {
      this.context.showToast('Please set an image!', 'error');
      return;
    }
    const smallUri = await captureRef(this.capture, {
      height: 200,
      format: "jpg",
      quality: 0.8
    });
    let photo;
    let photoBlob;
    let filename = "";
    try {
      photo = await fetch(smallUri)
      photoBlob = await photo.blob();
      filename = `${id}_preview.jpg`;
    } catch (e) {

    }
    try {
      await Storage.put(filename, photoBlob, {
        level: 'public',
        contentType: 'image/jpg'
      })
    } catch (e) {
      console.log(e)
      this.context.showToast('Error upload ' + e.message, 'error');
    }
  }

  awaitOneSecond() {
    return new Promise(resolve => setTimeout(resolve, 1000));
  }

  async saveMemeToCloud() {
    Keyboard.dismiss();
    try {
      if (!this.context.state.currentMeme?.title) {
        this.context.showToast('Please enter a title!', 'error');
        return;
      }
      if (!this.context.state.currentMeme?.imageUrl) {
        this.context.showToast('Please set an image!', 'error');
        return;
      }
      //this.context.setAppProp({ isBusy: true });

      let meme = { ...this.context.state.currentMeme };
      if (!meme.text1 || meme.text1 === 'Tap to add text') {
        meme.text1 = 'undefined';
        this.context.setCurrentMeme(meme);
      }
      if (!meme.text2 || meme.text2 === 'Tap to add text') {
        meme.text2 = 'undefined';
        this.context.setCurrentMeme(meme);
      }
      /* console.log('saveMemeToCloud', meme);
       return*/
      this.setState({ isBusy: true })
      await this.awaitOneSecond();

      const id = `${uuidv4()}_meme`;
      this.savePreview(id);
      captureRef(this.capture, {
        format: "jpg",
        quality: 1
      }).then(
        async uri => {
          //console.log("Image saved to", uri);
          let photo;
          let photoBlob;
          let filename = "";
          try {
            photo = await fetch(uri)
            photoBlob = await photo.blob();
            filename = `${id}.jpg`;
          } catch (e) {
            console.log(e)
            this.context.showToast('Save ' + e.message, 'error');
          }
          try {
            await Storage.put(filename, photoBlob, {
              level: 'public',
              contentType: 'image/jpg'
            })
          } catch (e) {
            console.log(e)
            this.context.showToast('Error upload ' + e.message, 'error');
          }
          //const imageKey = await Storage.get(filename, { level: 'public' });
          let meme = { ...this.context.state.currentMeme };
          meme.savedImage = filename;
          this.context.setCurrentMeme(meme, undefined, async () => {
            if (this.context.state.viewMeme) {
              await this.context.updateMeme();
            } else {
              await this.context.saveMeme();
            }

            //this.context.saveMeme(imageKey);
            this.setState({ isBusy: false })
            //this.context.setAppProp({ isBusy: false });
            SheetManager.hide(`save_sheet`);
            this.context.showToast('Meme saved to cloud!', 'success');
          })

          //console.log("imageKey", imageKey);
        },
        error => {
          console.error("Oops, snapshot failed", error)
          this.setState({ isBusy: false })
          SheetManager.hide(`save_sheet`);
          this.context.showToast('Oops, snapshot failed ' + error.message, 'error');
        }
      );
    } catch (e) {
      this.setState({ isBusy: false })
      SheetManager.hide(`save_sheet`);
      this.context.showToast('Error saving meme ' + e.message, 'error');
    }
  }

  render() {

    const renderSwitchTemplate = () => {
      switch (this.context.state.currentMeme.layout) {
        case '2TextAboveBelow':
          return <TwoTextOneImage owner={this}/>;
          break;
        case '1TextAbove':
          return <TextAboveOneImage owner={this}/>;
          break;
        case '1TextOverlap':
          return <OneTextOverlapOneImage owner={this}/>;
          break;
        case '2TextOverlap':
          return <TwoTextOverlapOneImage owner={this}/>;
          break;

      }
    }

    return (
      <View style={[this.props.style]}>
        <Text style={{
          color: colors.textColor,
          fontWeight: 'bold',
          fontSize: 20,
        }}>{this.context.state.currentMeme.title}</Text>
        <MemeCanvas layoutHeight={this.state.canvasHeight} layoutWidth={this.state.canvasWidth} style={style.fullSize} layout={this.context.state.currentMeme.layout}>
          <ViewShot style={{ width: '100%', height: '100%' }} ref={ref => this.capture = ref}>
            {
              renderSwitchTemplate()
            }
            {/* <MemeTxt textProp='text1' style={style.fullWidth}></MemeTxt>
                        <MemeImg setImageSize={this.setImageSize.bind(this)} style={style.fullWidth}></MemeImg>
                        {this.context.state.currentMeme.layout === '2TextAboveBelow' || this.context.state.currentMeme.layout === '2TextOverlap' ? <MemeTxt textProp='text2' style={style.fullWidth}></MemeTxt> : null}*/}
          </ViewShot>
        </MemeCanvas>

        <ActionSheet keyboardShouldPersistTaps={'handled'} containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="save_sheet">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <TextInput blurOnSubmit={true} onSubmitEditing={() => {
              Keyboard.dismiss();
              this.saveMemeToCloud();
            }} defaultValue={this.context.state.currentMeme.title || ""} placeholder='Meme title' onChangeText={(text: string) => {
              this.setMemeTitle(text)
            }} style={[style.fullWidth, style.textInput, {

              marginBottom: 15

            }]}></TextInput>

            <PrimaryButton isBusy={this.state.isBusy} align={'left'} onPress={this.saveMemeToCloud.bind(this)} icon={
              <FontAwesome5 size={20} color={colors.iconColorWhite} name='save'></FontAwesome5>} label={'Save Meme'} style={{
              width: '100%',
              marginTop: 10
            }}/>
            {/*<FontAwesome5.Button style={{}} name="save" color={"#ffffff"} backgroundColor={colors.primaryColor} onPress={this.saveMemeToCloud.bind(this)}>
              Save
            </FontAwesome5.Button>*/}
          </View>

        </ActionSheet>
      </View>

    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default Meme;
