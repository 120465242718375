import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Modal, Share, Platform } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { AntDesign, Entypo, EvilIcons, Feather, FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { UserMeme } from "../models";
import CommentBox from "./comment-box";
import CommentsModal from "./comments-modal";
import { SheetManager } from "react-native-actions-sheet";

interface State {

}

interface Props {
  meme: UserMeme,
  style?: ViewStyle
}

class MemeBar extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  reportPost() {
    if (!this.context.state.user) {
      this.context.setScreen('Sign In', true);
      return;
    }
    this.context.reportMeme(this.props.meme);
  }

  onLike() {

    this.context.likeMeme(this.props.meme);
  }

  componentDidMount() {

  }

  async shareMeme(meme: UserMeme) {
    //const shareUrl = `https://preview.imagenie.io?m=${meme.memeImage}&t=${meme.title}`;
    this.context.shareMeme(meme);
  }

  awaitOneSecond() {
    return new Promise(resolve => setTimeout(resolve, 200));
  }

  async onComment(item: UserMeme, e: any) {

    e.preventDefault();
    /*if (Platform.OS === 'ios') {
      this.context.setAppProp({ showMemeModal: false });
    }*/
    //this.context.setAppProp({ showMemeModal: false })
    if (Platform.OS === 'ios') {
      this.context.setAppProp({ showMemeModal: false });
      await this.awaitOneSecond();
    }
    this.context.setAppProp({
      showCommentBox: true,
      viewMeme: item,
      memeComments: [],
      memeCommentsLoading: false,
      memesCommentsToken: null
    }, () => {
      SheetManager.show('comments-box');
    })
    /*setTimeout(() => {
      SheetManager.show('comments-box');
    }, 300)*/

  }

  render() {

    return (
      <View style={[this.props.style, style.row, {
        borderTopWidth: 0,
        height: 50,
        paddingRight: 15,
        paddingLeft: 15,
        borderRadius: colors.borderRadius,
        borderColor: colors.primaryColor,
        backgroundColor: colors.secondaryColor,
        justifyContent: 'flex-start',
        alignItems: 'center'
      }]}>
        <TouchableOpacity onPress={this.onLike.bind(this)}>
          {this.context.state.memesLiked?.indexOf(this.props.meme.id) === -1 ?
            <Feather style={{ marginLeft: 10 }} name="heart" size={24} color={colors.iconColor}/> :
            <FontAwesome name="heart" style={{ marginLeft: 10 }} size={24} color={colors.iconColor}/>
          }
        </TouchableOpacity>

        <Text style={{ marginLeft: 10, fontSize: 20, color: colors.textColor }}>{this.props.meme.numLikes || 0}</Text>
        <TouchableOpacity onPress={this.onComment.bind(this, this.props.meme)}>
          <FontAwesome5 style={{
            marginLeft: 10,
            backgroundColor: null
          }} name="comment-dots" size={24} color={colors.iconColor}/>
        </TouchableOpacity>

        <Text style={{
          marginLeft: 10,
          fontSize: 20,
          color: colors.textColor
        }}>{this.props.meme.numComments || 0}</Text>
        <TouchableOpacity onPress={this.reportPost.bind(this, this.props.meme)}>
          <AntDesign style={{
            marginLeft: 10,

          }} name="flag" size={24} color={colors.iconColor}/>
        </TouchableOpacity>
        <View style={{ flex: 1 }}></View>
        <TouchableOpacity onPress={this.shareMeme.bind(this, this.props.meme)}>
          <EvilIcons name="share-google" size={35} color={colors.iconColor}/>
        </TouchableOpacity>


      </View>
    )
  };

}

const styles = StyleSheet.create({});

export default MemeBar;
