/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      isPremium
      memeNumCreatedCount
      reports
      mana
      boughtMana
      email
      picture
      firstName
      lastName
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      isPremium
      memeNumCreatedCount
      reports
      mana
      boughtMana
      email
      picture
      firstName
      lastName
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      isPremium
      memeNumCreatedCount
      reports
      mana
      boughtMana
      email
      picture
      firstName
      lastName
      createdAt
      updatedAt
    }
  }
`;
export const createUserMeme = /* GraphQL */ `
  mutation CreateUserMeme(
    $input: CreateUserMemeInput!
    $condition: ModelUserMemeConditionInput
  ) {
    createUserMeme(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        memeNumCreatedCount
        reports
        mana
        boughtMana
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeImage
      type
      title
      reports
      memeConfig
      numLikes
      numComments
      savedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUserMeme = /* GraphQL */ `
  mutation UpdateUserMeme(
    $input: UpdateUserMemeInput!
    $condition: ModelUserMemeConditionInput
  ) {
    updateUserMeme(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        memeNumCreatedCount
        reports
        mana
        boughtMana
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeImage
      type
      title
      reports
      memeConfig
      numLikes
      numComments
      savedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserMeme = /* GraphQL */ `
  mutation DeleteUserMeme(
    $input: DeleteUserMemeInput!
    $condition: ModelUserMemeConditionInput
  ) {
    deleteUserMeme(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        memeNumCreatedCount
        reports
        mana
        boughtMana
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeImage
      type
      title
      reports
      memeConfig
      numLikes
      numComments
      savedAt
      createdAt
      updatedAt
    }
  }
`;
export const createUserMemeLikes = /* GraphQL */ `
  mutation CreateUserMemeLikes(
    $input: CreateUserMemeLikesInput!
    $condition: ModelUserMemeLikesConditionInput
  ) {
    createUserMemeLikes(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        memeNumCreatedCount
        reports
        mana
        boughtMana
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeId
      meme {
        id
        userId
        user {
          id
          isPremium
          memeNumCreatedCount
          reports
          mana
          boughtMana
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
        }
        memeImage
        type
        title
        reports
        memeConfig
        numLikes
        numComments
        savedAt
        createdAt
        updatedAt
      }
      savedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUserMemeLikes = /* GraphQL */ `
  mutation UpdateUserMemeLikes(
    $input: UpdateUserMemeLikesInput!
    $condition: ModelUserMemeLikesConditionInput
  ) {
    updateUserMemeLikes(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        memeNumCreatedCount
        reports
        mana
        boughtMana
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeId
      meme {
        id
        userId
        user {
          id
          isPremium
          memeNumCreatedCount
          reports
          mana
          boughtMana
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
        }
        memeImage
        type
        title
        reports
        memeConfig
        numLikes
        numComments
        savedAt
        createdAt
        updatedAt
      }
      savedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserMemeLikes = /* GraphQL */ `
  mutation DeleteUserMemeLikes(
    $input: DeleteUserMemeLikesInput!
    $condition: ModelUserMemeLikesConditionInput
  ) {
    deleteUserMemeLikes(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        memeNumCreatedCount
        reports
        mana
        boughtMana
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeId
      meme {
        id
        userId
        user {
          id
          isPremium
          memeNumCreatedCount
          reports
          mana
          boughtMana
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
        }
        memeImage
        type
        title
        reports
        memeConfig
        numLikes
        numComments
        savedAt
        createdAt
        updatedAt
      }
      savedAt
      createdAt
      updatedAt
    }
  }
`;
export const createUserMemeComments = /* GraphQL */ `
  mutation CreateUserMemeComments(
    $input: CreateUserMemeCommentsInput!
    $condition: ModelUserMemeCommentsConditionInput
  ) {
    createUserMemeComments(input: $input, condition: $condition) {
      id
      userId
      reports
      comment
      user {
        id
        isPremium
        memeNumCreatedCount
        reports
        mana
        boughtMana
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeId
      savedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUserMemeComments = /* GraphQL */ `
  mutation UpdateUserMemeComments(
    $input: UpdateUserMemeCommentsInput!
    $condition: ModelUserMemeCommentsConditionInput
  ) {
    updateUserMemeComments(input: $input, condition: $condition) {
      id
      userId
      reports
      comment
      user {
        id
        isPremium
        memeNumCreatedCount
        reports
        mana
        boughtMana
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeId
      savedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserMemeComments = /* GraphQL */ `
  mutation DeleteUserMemeComments(
    $input: DeleteUserMemeCommentsInput!
    $condition: ModelUserMemeCommentsConditionInput
  ) {
    deleteUserMemeComments(input: $input, condition: $condition) {
      id
      userId
      reports
      comment
      user {
        id
        isPremium
        memeNumCreatedCount
        reports
        mana
        boughtMana
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
      }
      memeId
      savedAt
      createdAt
      updatedAt
    }
  }
`;
