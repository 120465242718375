import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../../constants/colors';
import style from '../../constants/style';
import { AppContext, IContext } from '../../store/app-provider';
import MemeTxt from "../meme-txt";
import MemeImg from "../meme-img";

interface State {

}

interface Props {
  owner: any
  style?: ViewStyle
}

class TwoTextOneImage extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  textHeight1: number = 0;
  textHeight2: number = 0;

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  render() {

    return (
      <View style={[style.fullSize]}>
        <MemeTxt textProp='text1'
                 onHeightChange={(h: number) => {
                   this.textHeight1 = h
                 }}
                 textStyle={[style.fullWidth, {
                   fontSize: 18,
                   textAlign: 'left',
                   color: colors.textColor
                 }]}
                 style={{
                   width: '100%',
                   minHeight: 40,
                   padding: 5,
                   paddingTop: 5,
                   paddingBottom: 5,
                   backgroundColor: '#ffffff'
                 }}></MemeTxt>
        <MemeImg setImageSize={(w: number, h: number) => {
          this.props.owner.setImageSize(w, h + this.textHeight1 + this.textHeight2)
        }} style={style.fullWidth}></MemeImg>
        <MemeTxt textProp='text2'
                 onHeightChange={(h: number) => {
                   this.textHeight2 = h
                 }}
                 textStyle={[style.fullWidth, {
                   fontSize: 18,
                   textAlign: 'left',
                   color: colors.textColor
                 }]}
                 style={{
                   width: '100%',
                   minHeight: 40,
                   padding: 5,
                   paddingTop: 5,
                   paddingBottom: 5,
                   backgroundColor: '#ffffff'
                 }}></MemeTxt>
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default TwoTextOneImage;
