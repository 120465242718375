import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, TextInput } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
//@ts-ignore
import { S3Image } from 'aws-amplify-react-native';
import { AntDesign, Feather, FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import CommentBox from "./comment-box";
import MemeBar from "./meme-bar";
import UserButton from "./user-button";
import PrimaryButton from "./primary-button";

interface State {

}

interface Props {
  item: any,

  onPress: any
  style?: ViewStyle
}

class MemeItem extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  openUserMenu(user: any) {

    this.context.openReportUser(user);
  }

  componentDidMount() {

  }

  reportUser() {

  }

  render() {

    return (

      this.props.item ? <View style={{ width: '100%', padding: 10 }}>
        <View style={[style.shadowMeme, {
          marginTop: 5,
          backgroundColor: '#fff',
          borderBottomLeftRadius: colors.borderRadius,
          borderBottomRightRadius: colors.borderRadius,
          borderTopLeftRadius: colors.borderRadius,
          borderTopRightRadius: colors.borderRadius,
          marginBottom: 5,
          width: '100%',
        }]}>
          <TouchableOpacity onPress={this.props.onPress.bind(this, this.props.item)}>
            <View style={[style.row, style.vcenter, {
              marginBottom: 15,
              padding: 15,
              paddingBottom: 5,
              borderRadius: colors.borderRadius,
              backgroundColor: '#fff'
            }]}>

              <UserButton hideBorder={true} onPress={this.openUserMenu.bind(this, this.props.item?.user)} user={this.props.item?.user} size={35}></UserButton>
              <Text style={{
                marginLeft: 10,
                flex: 1,

                color: colors.textColor,
                fontWeight: 'bold',
                fontSize: 20,
                marginTop: 0,
                marginBottom: 0
              }}>{this.props.item.title}</Text>
            </View>

            <View style={{
              width: '100%',
              height: 350,
              paddingLeft: 15,
              paddingRight: 15,
              overflow: 'hidden',
              borderWidth: 0,
              borderColor: '#ccc',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start'
            }}>

              <S3Image theme={{
                photo: {
                  borderRadius: colors.borderRadius,
                  width: '100%',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'flex-start',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start'
                }
              }} resizeMode='cover' level="public" imgKey={this.props.item.memeImage}/>
            </View>

          </TouchableOpacity>
          <MemeBar style={{
            marginTop: 10

          }} meme={this.props.item}/>

        </View></View> : <View></View>

    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default MemeItem;
