import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import ItemsList from "../components/items-list";
import * as queries from "../graphql/queries";
import MemeItem from "../components/meme-item";
import { UserMeme } from "../models";
import { Storage } from "aws-amplify";
import config from './../../src/aws-exports';
import CommentsModal from "../components/comments-modal";

interface Props {

}

interface State {

}

class MyPostsScreen extends React.Component<Props, State> {

  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);

  }

  async componentDidMount() {

  }

  componentDidUpdate(prevPops: any) {

  }

  gotoMeme(item: UserMeme) {

    this.context.setScreen('Edit', true, {}, async () => {
      let meme = JSON.parse(item.memeConfig);
      meme.imageUrl = await Storage.get(meme.savedImage)
      //`https://${config.aws_user_files_s3_bucket}.s3.${config.aws_user_files_s3_bucket_region}.amazonaws.com/public/${meme.savedImage}`
      console.log("meme", meme)
      this.context.setCurrentMeme(meme, item);
    });

  }

  render() {
    const renderItem = ({ item }: any) => {
      return (
        <MemeItem item={item} onPress={this.gotoMeme.bind(this)}/>

      )
    }

    return (
      <View style={style.page}>
        <View style={[style.container]}>
          {this.context.state.user ?
            <ItemsList query={queries.getPostsByTimeAndUser} stateKey={'myMemesList'} queryString={'getPostsByTimeAndUser'} filter={{
              userId: this.context.state.user.id,
              limit: 10,
              sortDirection: 'DESC'
            }} renderItem={renderItem} stateItem={this.context.state.myMemesList}/> : null}

        </View>
        {/* <CommentsModal meme={this.context.state.viewMeme}/>*/}
      </View>

    )
  };

}

export default (withTranslation()(MyPostsScreen));

const styles = StyleSheet.create({
  input: {
    padding: 5,
    height: 35,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: colors.textColor,
    color: colors.textColor
  },
  screenItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 10,
    width: '100%',
    height: '100%',
    backgroundColor: colors.textColor
  }
});
