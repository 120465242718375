import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  Modal,
  Platform,
  ActivityIndicator
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { UserMeme } from "../models";
import { FontAwesome5 } from "@expo/vector-icons";
import CommentBox from "./comment-box";
import MemeBar from "./meme-bar";
///@ts-ignore
import { S3Image } from 'aws-amplify-react-native';
import UserButton from "./user-button";

interface State {

}

interface Props {

  style?: ViewStyle
}

class BuyModal extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  render() {

    return (
      <View>
        {this.context.state.showBuyModal ? <Modal
          animationType="fade" style={{
          display: "flex",
          bottom: 0,
          top: '50%',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }}

          transparent={true}
          visible={this.context.state.showBuyModal}
          onRequestClose={() => {
            this.context.setAppProp({
              showBuyModal: false,
            })
          }}>
          <View style={[style.fullWidth, style.column, {
            height: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            padding: 0,
          }]}>
            <TouchableOpacity onPress={() => {
              this.context.setAppProp({
                showBuyModal: false,
              })
            }} style={{ height: '100%', width: '100%' }}></TouchableOpacity>
            <View style={[{
              height: 440,
              width: '100%',
              maxWidth: colors.maxWidth,
              padding: 10,
              display: 'flex',
              borderRadius: 10,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              paddingTop: 0,
              backgroundColor: "#fff"
            }]}>
              <TouchableOpacity onPress={() => {
                this.context.setAppProp({ showBuyModal: false })
              }} style={{
                width: '100%',
                borderRadius: colors.borderRadius,
                marginBottom: 20,
                backgroundColor: "#fff",
                height: 30,
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <FontAwesome5 name="angle-down" size={20} color={colors.iconColor}/>
              </TouchableOpacity>
              <View style={[style.column, style.fullWidth, { justifyContent: 'space-between', flex: 1 }]}>
                {this.context.state.appConfig.products[Platform.OS === 'ios' ? 'ios' : 'android'].map((product: any, i: number) => {
                    return <TouchableOpacity onPress={() => {
                      this.context.setAppProp({ showBuyModal: false });
                      this.context.buyProduct(product);
                    }} key={i}>
                      <View style={[style.row, style.fullWidth, style.hcenter, style.vcenter, {
                        padding: 10,
                        height: 80,
                        paddingBottom: 20,
                        borderWidth: 0,
                        backgroundColor: '#fff',
                        borderColor: colors.primaryColor,
                        borderRadius: 10
                      }, style.shadowBuy]}>
                        <View style={{
                          width: 60,
                          height: 60,
                          borderRadius: 10,
                          backgroundColor: '#fde8ed',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          <FontAwesome5 size={25} color={colors.primaryColor} name='cart-plus'></FontAwesome5>
                        </View>

                        <View style={[style.column, style.hcenter, style.vcenter, {
                          flex: 1,
                          margin: 3,
                          marginLeft: 20,
                          alignItems: 'flex-start'
                        }]}>
                          <Text style={{
                            color: colors.textColor,
                            fontSize: 18,
                            fontWeight: 'normal'
                          }}>{product.title}</Text>
                          <View style={[style.row]}>
                            <Text style={{
                              color: colors.textColor,
                              marginTop: 10,
                              fontSize: 18,
                              fontWeight: 'normal'
                            }}>{"$" + product.price + " => "}</Text>
                            <Text style={{
                              color: colors.primaryColor,
                              marginTop: 10,
                              fontSize: 18,
                              fontWeight: 'bold'
                            }}>{product.mana}</Text>
                            <Text style={{
                              color: colors.textColor,
                              marginTop: 10,
                              fontSize: 18,
                              fontWeight: 'normal'
                            }}>{" mana"}</Text>
                          </View>

                        </View>

                      </View></TouchableOpacity>
                  }
                )}
              </View>
            </View>

          </View>
        </Modal> : null}

      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default BuyModal;
