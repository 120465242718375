import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, ActivityIndicator, Platform } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { FontAwesome, SimpleLineIcons } from "@expo/vector-icons";
import * as Progress from 'react-native-progress';

interface State {
  progress: number;
}

interface Props {
  isSecondary?: boolean;
  progress?: boolean;
  isBusy?: boolean
  label: string;
  icon?: any;
  align?: 'left' | 'right';
  onPress?: any
  style?: ViewStyle
}

class PrimaryButton extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  interval: any

  constructor(props: any) {
    super(props);
    this.state = { progress: -1 }

  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.isBusy !== this.props.isBusy && this.props.isBusy && this.props.progress) {
      this.setState({ progress: 0 })
      let seconds = 0;
      this.interval = setInterval(() => {
        console.log("progress", this.state.progress)
        //set progress from 0 to 1 in 0.1 seconds with 1 being 25 seconds
        seconds += 0.1;
        this.setState({ progress: seconds / 30 })
        if (seconds >= 30) {
          clearInterval(this.interval)
          this.setState({ progress: 1 })
        }
      }, 100)
    }
    if (prevProps.isBusy !== this.props.isBusy && !this.props.isBusy && this.props.progress && this.interval) {
      clearInterval(this.interval);
      this.setState({ progress: 1 })
    }
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  render() {
    const busyIndicator = () => {
      if (this.props.isBusy) {
        return this.props.progress && Platform.OS !== 'web' ?
          <View style={[style.row, style.fullHeight, style.vcenter, {
            position: 'absolute',
            right: 0,
            paddingLeft: 10
          }]}>
            <Progress.Pie borderColor={colors.primaryColor} animated={true} unfilledColor={"#ffffff"} color={colors.primaryColor} progress={this.state.progress} size={25}/>

          </View> : <View style={[style.row, style.fullHeight, style.vcenter, {
            position: 'absolute',
            right: 0,
            paddingLeft: 10
          }]}>

            <ActivityIndicator size="small" color={colors.iconColorWhite}/>
          </View>

      }
    }
    return (
      <TouchableOpacity disabled={this.props.isBusy} onPress={this.props.onPress} style={[this.props.style, this.props.isSecondary ? null : style.shadowButton, {

        padding: 8,
        borderWidth: 1,
        borderColor: colors.primaryColor,
        borderRadius: colors.borderRadius,
        backgroundColor: this.props.isSecondary ? '#fff' : colors.primaryColor,
      }]}>
        <View style={[style.row, style.vcenter, {
          padding: 6,
          flexDirection: this.props.align === 'right' ? 'row-reverse' : 'row'
        }]}>
          {this.props.icon}
          <Text style={{
            fontSize: 18,
            fontWeight: 'bold',
            flex: 1,
            textAlign: 'center',
            color: this.props.isSecondary ? colors.primaryColor : '#fff'
          }}>{this.props.label} </Text>
          {busyIndicator()}
        </View>
      </TouchableOpacity>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default PrimaryButton;
