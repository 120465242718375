import { FontAwesome } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  TouchableWithoutFeedback,
  View,
  Image,
  TextInput,
  FlatList,
  TouchableNativeFeedback,
  ActivityIndicator,
  Linking,
  Platform,
  AppState,
  ScrollView
} from 'react-native';

import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from "../store/app-provider";

interface Props {

}

interface State {

}

class TermsScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);

  }

  async componentDidMount() {

  }

  render() {

    return (

      <View style={[style.container, { padding: 10 }]}>
        <ScrollView style={[style.fullSize, { padding: 20 }]}>
          <Text style={[style.fullSize, { color: colors.textColor }]}>
            {this.context.state.appConfig?.terms?.replace(/<br\/>/gi, '\n')}
          </Text>
        </ScrollView>
      </View>

    )
  };

}

export default TermsScreen;

const styles = StyleSheet.create({});
