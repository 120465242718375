import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Modal } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { UserMeme } from "../models";
import { FontAwesome5 } from "@expo/vector-icons";
import CommentBox from "./comment-box";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";

interface State {

}

interface Props {
  meme: UserMeme
  style?: ViewStyle
}

class CommentsModal extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  render() {

    return (

      this.context.state.showCommentBox ? <ActionSheet keyboardShouldPersistTaps={'handled'} onClose={() => {
        this.context.setAppProp({ showCommentBox: false })
      }} containerStyle={{
        zIndex: 9999,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderRadius: colors.borderRadius,
        maxWidth: colors.maxWidth
      }} elevation={3333} defaultOverlayOpacity={0.5} id={`comments-box`}>

        <View style={[style.fullWidth, style.column, {
          height: 400,

          justifyContent: 'flex-end',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0)',
          padding: 0,
        }]}>
          {/* <TouchableOpacity onPress={() => {
              SheetManager.hide(`comments-box`);
              this.context.setAppProp({
                showCommentBox: false,
              })
            }} style={{ height: '50%', width: '100%' }}></TouchableOpacity>*/}
          <View style={[{
            height: '100%',
            width: '100%',
            maxWidth: colors.maxWidth,
            padding: 20,

            borderRadius: 10,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            paddingTop: 0,
            backgroundColor: "#fff"
          }]}>
            <TouchableOpacity onPress={() => {
              SheetManager.hide(`comments-box`);
              this.context.setAppProp({ showCommentBox: false })
            }} style={{
              width: '100%',
              borderRadius: colors.borderRadius,
              marginBottom: 20,
              backgroundColor: "#fff",
              height: 30,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <FontAwesome5 name="angle-down" size={20} color={colors.iconColor}/>
            </TouchableOpacity>
            <CommentBox style={{ width: '100%', flex: 1 }} meme={this.context.state.viewMeme}/>
          </View>

        </View>
      </ActionSheet> : null

    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default CommentsModal;
