import { Amplify, API, Auth, graphqlOperation } from 'aws-amplify';
import config from './../aws-exports';
import * as queries from './../graphql/queries';
import Lambda from 'aws-sdk/clients/lambda';

var AWS = require('aws-sdk');
import {
  createUser,
  createUserMeme,
  createUserMemeComments,
  createUserMemeLikes, deleteUser, updateUser,
  updateUserMeme, updateUserMemeComments
} from "./../graphql/mutations";
import { IMeme } from "../models/meme";
import { UserMeme, UserMemeComments } from "../models";

const signIn = () => {

}
const setAuthType = (type: 'AWS_IAM' | 'API_KEY') => {
  config.aws_appsync_authenticationType = type;
  API.configure(config);
}
const img = async (query: string) => {

  const credentials = await Auth.currentCredentials()
  AWS.config.update({
    region: 'us-east-1',
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey
  });
  const lambda = new Lambda({
    credentials: Auth.essentialCredentials(credentials)
  });
  return new Promise((resolve, reject) => {
    lambda.invoke({
      FunctionName: 'imagenieimag-prod',
      Payload: JSON.stringify({ q: escape(query) }),
    }, (err, data) => {
      if (err) {
        reject(err);
      } else {

        resolve(data);
      }
    });
  })
}
const getImage = async (query: string, type: string) => {
  /*config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3000"
  Amplify.configure(config);*/
  //setAuthType('AWS_IAM')

  let result: any;
  try {
    /*if (type === 'stableai') {
      result = (await API.get('imagenierestapi', '/rest/get-image?q=' + escape(query), {}))
      return result.result.output;
    } else {
      result = await img(query)
      return JSON.parse(result.Payload).body.result?.data?.map((result: any) => result.generation.image_path)

    }*/
    result = await img(query);
    const real = JSON.parse(result.Payload).body
    console.log("result", real.error)
    if (real.error) {
      //toast.show(real.error, { type: 'error' });
      return new Error(real.error);
    }
    //console.log("result", result)
    return real.result.output;
    //console.log("result", result)

  } catch (e: any) {
    console.log(e.response)
    //toast.show(e.response.data.error, { type: 'error' });
    return new Error(e.response.data.error);
  }
  /*let result: any
  result = await img(query)
  return JSON.parse(result.Payload).body;*/

  /**/
}

const getAppConfig = async () => {
  /*  config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3000"
   Amplify.configure(config); */
  //setAuthType('AWS_IAM')
  let result
  try {
    result = (await API.get('imagenierestapi', '/rest/config', {}))
    return result;
  } catch (e: any) {
    console.log(e)
    toast.show(e.message, { type: 'error' });
    return null;
  }
}
const getAccount = async (id: string) => {
  return (await API.graphql({ query: queries.getUser, variables: { id: id } }) as any).data.getUser;
}
const createAccount = async (account: any) => {
  setAuthType('AWS_IAM')
  const acc = (await API.graphql(graphqlOperation(createUser, { input: account })) as any).data.createUser;
  setAuthType('API_KEY')
  return acc;
}
const deleteUserMe = async (userId: string, username: string) => {
  setAuthType('AWS_IAM')
  /*config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3000"
  Amplify.configure(config);*/
  await API.graphql(graphqlOperation(deleteUser, { input: { id: userId } }))
  const result = (await API.del('imagenierestapi', '/rest/user/' + username, {}))

  setAuthType('API_KEY')
  return result;
}
const sendMessage = async (mail: string, subject: string, message: string) => {
  setAuthType('AWS_IAM')
  /*config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3000"
  Amplify.configure(config);*/
  try {
    const result = (await API.post('imagenierestapi', '/rest/message/', { body: { mail, subject, message } }))
    setAuthType('API_KEY');
  } catch (e) {
    //toast.show(e.response.data.message, { type: 'error' });
    setAuthType('API_KEY');
    return new Error(e.response.data.message);
  }

}
const updateAccount = async (id: string, data: any) => {
  setAuthType('AWS_IAM')
  const account = { id, ...data };
  const acc = (await API.graphql(graphqlOperation(updateUser, { input: account })) as any).data.updateUser;
  setAuthType('API_KEY')
  return acc;
}

const getPListItems = async (query: any, queryString: string, filter: any, nextToken?: string) => {
  filter.nextToken = nextToken;
  // console.log("nextToken", nextToken)
  const items = ((await API.graphql(graphqlOperation(query, filter))) as any).data[queryString];
  //console.log("memes", items)
  return items;
}

const getMeme = async (memeId: string) => {
  const meme = ((await API.graphql(graphqlOperation(queries.getUserMeme, { id: memeId }))) as any).data.getUserMeme;
  return meme;
}
const saveMeme = async (meme: IMeme, userId: string, error: any) => {
  setAuthType('AWS_IAM')
  const toSaveMeme = {
    userId,
    savedAt: new Date().toISOString(),
    memeImage: meme.savedImage,
    type: "public",
    title: meme.title,
    memeConfig: JSON.stringify(meme)
  }
  let savedMeme;
  try {
    savedMeme = (await API.graphql(graphqlOperation(createUserMeme, { input: toSaveMeme })) as any).data.createUserMeme;
  } catch (e) {
    error(e);
  }

  setAuthType('API_KEY')
  return savedMeme;
}
const postComment = async (meme: any, userId: string, comment: string, error: any) => {
  setAuthType('AWS_IAM')
  const comm = {
    userId,
    memeId: meme.id,
    savedAt: new Date().toISOString(),
    comment
  }
  let memeComment;
  try {
    memeComment = (await API.graphql(graphqlOperation(createUserMemeComments, { input: comm })) as any).data.createUserMemeComments;
    const numComments = (meme.numComments || 0) + 1;
    const toSaveMeme = {
      id: meme.id,
      numComments
    }
    const savedMeme = (await API.graphql(graphqlOperation(updateUserMeme, { input: toSaveMeme })) as any).data.updateUserMeme;
    meme.numComments = numComments;
  } catch (e) {
    error(e);
  }

  setAuthType('API_KEY')
  return memeComment;
}
const likeMeme = async (meme: any, userId: string, error: any, success: any) => {
  setAuthType('AWS_IAM')
  const liked = (await API.graphql({
    query: queries.listUserMemeLikes,
    variables: { filter: { and: [{ userId: { eq: userId } }, { memeId: { eq: meme.id } }] } }
  }) as any).data.listUserMemeLikes.items[0];
  if (liked) {
    //error({ message: "Already liked" });
    setAuthType('API_KEY')
    return new Error("Already liked");
  }
  const like = {
    userId,
    memeId: meme.id,
    savedAt: new Date().toISOString()
  }
  try {

    (await API.graphql(graphqlOperation(createUserMemeLikes, { input: like })) as any).data.createUserMemeLikes;
    const numLikes = (meme.numLikes || 0) + 1;
    const toSaveMeme = {
      id: meme.id,
      numLikes
    }
    const savedMeme = (await API.graphql(graphqlOperation(updateUserMeme, { input: toSaveMeme })) as any).data.updateUserMeme;
    meme.numLikes = numLikes;
    success();
  } catch (e) {
    //error(e);
  }

  setAuthType('API_KEY')

}
const reportMeme = async (meme: any, userId: string, error: any, success: any) => {
  setAuthType('AWS_IAM')
  try {
    const reports = meme.reports || [];
    if (!reports.includes(userId)) {
      reports.push(userId);
      const toSaveMeme = {
        id: meme.id,
        reports
      }
      const savedMeme = (await API.graphql(graphqlOperation(updateUserMeme, { input: toSaveMeme })) as any).data.updateUserMeme;
      meme.reports = reports;
      success();
    } else {
      error({ message: "Already reported" });
    }
  } catch (e) {
    error(e);
  }
  setAuthType('API_KEY')

}
const reportUser = async (user: any, userId: string, error: any, success: any) => {
  setAuthType('AWS_IAM')
  try {
    const reports = user.reports || [];
    if (!reports.includes(userId)) {
      reports.push(userId);
      const toSaveUser = {
        id: user.id,
        reports
      }
      const savedUser = (await API.graphql(graphqlOperation(updateUser, { input: toSaveUser })) as any).data.updateUser;
      user.reports = reports;
      success();
    } else {
      error({ message: "Already reported" });
    }
  } catch (e) {
    error(e);
  }
  setAuthType('API_KEY')

}
const reportComment = async (userComment: any, userId: string, error: any, success: any) => {
  setAuthType('AWS_IAM')
  console.log("userComment", userComment)
  try {
    const reports = userComment.reports || [];
    if (!reports.includes(userId)) {
      console.log("kaka 1", reports)
      reports.push(userId);
      const toSaveComment = {
        id: userComment.id,
        reports
      }
      const savedMeme = (await API.graphql(graphqlOperation(updateUserMemeComments, { input: toSaveComment })) as any).data.updateUserMemeComments;
      userComment.reports = reports;
      success();
    } else {
      console.log("kaka 2")
      error({ message: "Already reported" });
    }
  } catch (e) {
    error(e);
  }
  setAuthType('API_KEY')

}
const updateMeme = async (meme: IMeme, viewMeme: UserMeme, userId: string) => {
  setAuthType('AWS_IAM')
  const toSaveMeme = {
    id: viewMeme.id,
    userId,
    savedAt: new Date().toISOString(),
    memeImage: meme.savedImage,
    type: "public",
    title: meme.title,
    memeConfig: JSON.stringify(meme)
  }
  const savedMeme = (await API.graphql(graphqlOperation(updateUserMeme, { input: toSaveMeme })) as any).data.updateUserMeme;
  setAuthType('API_KEY')
  return savedMeme;
}
export default {
  signIn,
  getImage,
  getAccount,
  sendMessage,
  createAccount,
  deleteUserMe,
  updateAccount,
  saveMeme,
  updateMeme,
  getMeme,
  getAppConfig,
  postComment,
  likeMeme,
  reportMeme,
  reportUser,
  reportComment,
  getPListItems,
};
