import { NavigationContainer } from "@react-navigation/native";
import React from "react";
import navigationService from "../services/navigation-service";
import { AppContext, IContext } from "../store/app-provider";
import HomeScreen from './../screens/home';
import Toast from 'react-native-toast-notifications';
import PrivacyScreen from './../screens/privacy';
import BuilderScreen from './../screens/builder';
import MemeScreen from './../screens/meme';
import TermsScreen from './../screens/terms';
import MyPostsScreen from './../screens/my-posts';
import MyAccountScreen from './../screens/my-account';
import MyLikedScreen from './../screens/my-liked';
import LoginScreen from './../screens/login';
import Header from './../components/header';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

const Stack = createNativeStackNavigator();
import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { Auth, Hub } from "aws-amplify";

interface State {

}

interface Props {

}

const link: LinkingOptions<any> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {

      Home: {

        path: '/',
      },
      Privacy: {

        path: 'privacy',
      },
      Builder: {
        path: 'create',
      },
      MyAccount: {
        path: 'my-account',
      },
      Edit: {
        path: 'edit',
      },
      MyPosts: {
        path: 'my-posts',
      },
      MyLiked: {
        path: 'my-liked',
      },
      Meme: {

        path: 'meme',
      },
      Terms: {

        path: 'terms',
      },
      'Sign In': {
        path: 'login',
      }
    },

  },
}

class AppNavigator extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}
    this.getUserCredentials();
    Hub.listen('auth', ({ payload: { event, data } }) => {

      switch (event) {
        case 'signIn':
          //console.log('auth state signIn', event);
          this.getUserCredentials();
          break;
        case 'signOut':

          break;
        case 'customOAuthState':

      }
    });
  }

  async getUserCredentials() {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      this.context.setUser(user);
      //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(checkUser(user))
      console.log("Cognito has a user")
    } catch (e) {
      console.log("Cognito has no user")
      //(store.dispatch as ThunkDispatch<User, void, ActionTypes>)(checkUser({ attributes: { "email": "yonodo10@gmail.com", "sub": "920a4dd7-6212-48a8-b873-b7f1a72a2901" } }))

    }

  }

  navigationStateChange = (state: any) => {
    const screen = state?.routes?.[state.routes?.length - 1]?.name;
    const params = state?.routes?.[state.routes?.length - 1]?.params;
    this.context.setScreen(screen, false, params);
    console.log("navigationStateChange", screen, params);
    //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen(screen, params, true));

  }
  readyNav = () => {

    this.navigationStateChange(navigationService.getNavigation().getState())

  }

  render() {

    return (

      <NavigationContainer  linking={link} onReady={this.readyNav.bind(this)} onStateChange={this.navigationStateChange.bind(this)} ref={navigatorRef => {
        navigationService.setTopLevelNavigator(navigatorRef);
      }}>
        <Stack.Navigator  screenOptions={{
          animation: 'simple_push',
          gestureEnabled: true,
          animationTypeForReplace: 'push'

        }}>
          <Stack.Screen
            name="Home"
            component={HomeScreen}
            options={{ headerTitleAlign: 'center', title: "ImaGenie", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Sign In"
            component={LoginScreen}
            options={{ headerTitleAlign: 'center', title: "Sign In", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Builder"
            component={BuilderScreen}
            options={{ headerTitleAlign: 'center', title: "Create", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="MyAccount"
            component={MyAccountScreen}
            options={{ headerTitleAlign: 'center', title: "My Account", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Edit"
            component={BuilderScreen}
            options={{ headerTitleAlign: 'center', title: "Edit", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="MyPosts"
            component={MyPostsScreen}
            options={{ headerTitleAlign: 'center', title: "My Posts", header: (props) => <Header {...props} /> }}
          /><Stack.Screen
          name="MyLiked"
          component={MyLikedScreen}
          options={{ headerTitleAlign: 'center', title: "My Liked", header: (props) => <Header {...props} /> }}
        />
          <Stack.Screen
            name="Meme"
            component={MemeScreen}
            options={{ headerTitleAlign: 'center', title: "ImaGenie", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Privacy"
            component={PrivacyScreen}
            options={{ headerTitleAlign: 'center', title: "Privacy Policy", header: (props) => <Header {...props} /> }}
          />

          <Stack.Screen
            name="Terms"
            component={TermsScreen}
            options={{
              headerTitleAlign: 'center',
              title: "Terms and Conditions",
              header: (props) => <Header {...props} />
            }}
          />

        </Stack.Navigator>
        <Toast ref={(ref: any) => global['toast'] = ref}/>
      </NavigationContainer>
    )
  }
}

export default AppNavigator;
