import * as React from 'react';
import { ActivityIndicator, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import { Entypo, FontAwesome, FontAwesome5 } from "@expo/vector-icons";
///@ts-ignore
import { S3Image } from 'aws-amplify-react-native';
import MemeBar from "../components/meme-bar";
import UserButton from "../components/user-button";
import Alert from "../components/alert";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import CommentsModal from "../components/comments-modal";

interface Props {

}

interface State {

}

class MemeScreen extends React.Component<Props, State> {

  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);

  }

  async componentDidMount() {

    setTimeout(() => {
      let memeId = this.context.state.routeParams?.post;
      this.context.getViewMeme(memeId);

    }, 100);
    setTimeout(() => {
      if (this.context.state.os !== 'undefined' && this.context.state.appConfig.androidApp && this.context.state.appConfig.iOSApp) {
        SheetManager.show('download_sheet')
      }

    }, 4000);
  }

  componentDidUpdate(prevPops: any) {

  }

  render() {

    const busyIndicator = () => {
      if (this.context.state.isBusy) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator}/>
      }
    }

    return (
      <View style={style.page}>
        <View style={[style.container, { maxWidth: undefined }]}>
          {this.context.state.viewMeme ?
            <View style={[style.fullSize, {
              backgroundColor: colors.grey,
              padding: 0,
              paddingTop: 10
            }]}>


              <View style={[style.column, {
                alignItems: 'center',
                flex: 1,
                justifyContent: 'center',
                padding: 0
              }]}>
                <View style={[style.row, style.fullWidth, style.vcenter, {
                  padding: 0,
                  justifyContent: 'flex-start'
                }]}>

                  <UserButton user={this.context.state.viewMeme?.user} size={36}></UserButton>
                  <Text style={{
                    marginLeft: 10,
                    color: colors.textColor,
                    fontWeight: 'bold',
                    fontSize: 20,
                    marginTop: 10,
                    marginBottom: 10
                  }}>{this.context.state.viewMeme.title}</Text>
                </View>


                <S3Image theme={{
                  photo: {
                    resizeMode: "contain",

                    width: '100%',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'flex-start',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                  }
                }} resizeMode={"contain"} level="public" imgKey={this.context.state.viewMeme.memeImage}/>
                <MemeBar style={{
                  width: '100%',
                  maxWidth: colors.maxWidth,
                  marginTop: 10
                }} meme={this.context.state.viewMeme}/>
              </View>
            </View> : null

          }
        </View>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="download_sheet">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={this.context.state.os === "android" ?
              <Entypo name="google-play" size={24} color={colors.iconColor}/> :
              <FontAwesome5 name="app-store" size={24} color={colors.iconColor}/>} style={{ width: '100%' }} onAccept={() => {
              this.context.gotoStore();
              SheetManager.hide('download_sheet')
            }} message={"For a better experience and for creating stunning AI driven memes, you can download the mobile app from the store"} acceptLabel={"Download"}/>
          </View>

        </ActionSheet>
        <CommentsModal meme={this.context.state.viewMeme}/>
      </View>
    )
  };

}

export default (withTranslation()(MemeScreen));

const styles = StyleSheet.create({
  input: {
    padding: 5,
    height: 35,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: colors.textColor,
    color: colors.textColor
  },
  screenItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 10,
    width: '100%',
    height: '100%',
    backgroundColor: colors.textColor
  }
});
