import {registerRootComponent} from 'expo';
import {Platform} from 'react-native';
import {AppContext, AppProvider} from './src/store/app-provider';
//import App from './AppMobile';
import App from './AppWeb';

///if (Platform.OS === 'web') {
/*var corescript = document.createElement('script');
corescript.type = 'text/javascript';
corescript.src = 'https://www.paypalobjects.com/donate/sdk/donate-sdk.js';
var parent = document.getElementsByTagName('head').item(0);
parent.appendChild(corescript);

var adsense = document.createElement('script');
adsense.type = 'text/javascript';
adsense.crossorigin = "anonymous";
adsense.async = true;
adsense.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3900405957506493';
parent.appendChild(adsense);*/

/*(function () {
  var s = document.createElement("script");
  s.src = "https://remotejs.com/agent/agent.js";
  s.setAttribute("data-consolejs-channel", "8b90dae4-4d12-8807-ba6d-6f001501eb3c");
  document.head.appendChild(s);
})()*/

//console.log("lang", navigator.languages)

/*var element = document.createElement('style'),
    sheet;

// Append style element to head
parent.appendChild(element);

// Reference to the stylesheet
sheet = element.sheet;
var styles = '.youtube-player {';
styles += 'width:100%;';
styles += 'height:100%;'
styles += '}';


// Add the first CSS rule to the stylesheet
sheet.insertRule(styles, 0);*/
//}
//App.contextType = AppContext;
registerRootComponent(App);


