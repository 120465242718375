import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, RefreshControl, FlatList } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import * as queries from './../graphql/queries';

interface State {

}

interface Props {
  query: any,
  queryString: any,
  filter: any
  style?: ViewStyle
  renderItem: any,
  stateKey: string,
  stateItem: { nextToken?: string, items: any[], loading: boolean }
}

class ItemsList extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {
    this.getListItems(true);
  }

  getListItems = async (refresh: boolean) => {
    this.context.getListItems(this.props.stateKey, this.props.query, this.props.queryString, this.props.filter, refresh);
  }

  render() {

    return (
      <View style={[style.fullWidth, { flex: 1 }]}>

        {!this.props.stateItem.items?.length && !this.props.stateItem.loading ?
          <View style={[style.fullWidth, style.column, style.vcenter, style.hcenter, { flex: 1 }]}><Text>Nothing to show here</Text></View> :
          <FlatList ref={(ref) => {

          }} refreshControl={
            <RefreshControl
              enabled={true}
              refreshing={this.props.stateItem.loading || false}
              onRefresh={() => {
                this.getListItems(true);
              }}
            />
          } horizontal={false} onEndReached={() => {
            this.getListItems(false);
          }} contentContainerStyle={{
            width: '100%',
            flexDirection: 'column'
          }} data={this.props.stateItem.items} style={{
            flex: 1,
            width: '100%'
          }} renderItem={this.props.renderItem} keyExtractor={(item) => item.id}
          />
        }
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default ItemsList;
