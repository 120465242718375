import { Feather, FontAwesome, MaterialIcons, SimpleLineIcons } from '@expo/vector-icons';
import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  Button,
  TextInput,
  ActivityIndicator, FlatList, RefreshControl, Keyboard
} from 'react-native';

import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import api from '../services/api';
import { IMeme } from '../models/meme';
import PrimaryButton from "./primary-button";
import MemeItem from "./meme-item";

interface State {

  text: string;
  isBusy: boolean,
  images: any[],
}

interface Props {
  setImageSize: any
  style?: ViewStyle
}

export type CancelPromise = ((reason?: Error) => void) | undefined;
export type ImageSize = { width: number; height: number };

interface ImageSizeOperation {
  start: () => Promise<ImageSize>;
  cancel: CancelPromise;
}

class MemeImg extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  image: any

  constructor(props: any) {
    super(props);
    this.state = { isBusy: false, text: "", images: [] };

  }

  edit() {
    SheetManager.show("edit_img_sheet");
  }

  componentDidMount() {

  }

  loadPhotoGallery() {

  }

  takePhoto() {

  }

  getImageSize = (uri: string): ImageSizeOperation => {
    let cancel: CancelPromise;
    const start = (): Promise<ImageSize> =>
      new Promise<{ width: number; height: number }>((resolve, reject) => {
        cancel = reject;
        Image.getSize(
          uri,
          (width, height) => {
            cancel = undefined;
            resolve({ width, height });
          },
          error => {
            reject(error);
          }
        );
      });

    return { start, cancel };
  };

  async loadMagicPhoto() {
    let mana = this.context.state.user.mana;
    if (!this.state.text?.length) {
      this.context.showToast("Please enter text", "error");
      return;
    }
    if (!mana) {
      SheetManager.hide('edit_img_sheet');
      this.context.showToast('You don\'t have enough mana!', 'error');
      this.context.setAppProp({ showBuyModal: true });
      return;
    }
    /*const images = [{
      "id": 0,
      "i": "https://openailabsprodscus.blob.core.windows.net/private/user-qNn4c5iBSiIXU9WhX2XRexIE/generations/generation-27QqnqlOK2gJ3GLvDRtv5XA7/image.webp?st=2022-08-28T14%3A39%3A56Z&se=2022-08-28T16%3A37%3A56Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/webp&skoid=15f0b47b-a152-4599-9e98-9cb4a58269f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2022-08-28T15%3A12%3A17Z&ske=2022-09-04T15%3A12%3A17Z&sks=b&skv=2021-08-06&sig=6LGLFSeG45MBXdnjOoKUD2nWRgi3rrIdXKxA52r0suw%3D"
    }, {
      "id": 1,
      "i": "https://openailabsprodscus.blob.core.windows.net/private/user-qNn4c5iBSiIXU9WhX2XRexIE/generations/generation-aYYC8907yi2mofhVzL3T6lBe/image.webp?st=2022-08-28T14%3A39%3A56Z&se=2022-08-28T16%3A37%3A56Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/webp&skoid=15f0b47b-a152-4599-9e98-9cb4a58269f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2022-08-28T15%3A12%3A17Z&ske=2022-09-04T15%3A12%3A17Z&sks=b&skv=2021-08-06&sig=7dVzrRl5Pw7JmjCksHL5hy5jpS6xi8K9ENp3mZWPpuY%3D"
    }, {
      "id": 2,
      "i": "https://openailabsprodscus.blob.core.windows.net/private/user-qNn4c5iBSiIXU9WhX2XRexIE/generations/generation-xGA53U6tcJ6koD5TfO9jGDBw/image.webp?st=2022-08-28T14%3A39%3A56Z&se=2022-08-28T16%3A37%3A56Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/webp&skoid=15f0b47b-a152-4599-9e98-9cb4a58269f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2022-08-28T15%3A12%3A17Z&ske=2022-09-04T15%3A12%3A17Z&sks=b&skv=2021-08-06&sig=6%2BIPX6Dah99pNi0aFSDERNPqaugHzQDUOGC65T4NGew%3D"
    }, {
      "id": 3,
      "i": "https://openailabsprodscus.blob.core.windows.net/private/user-qNn4c5iBSiIXU9WhX2XRexIE/generations/generation-QDDjtN3lxOHRoMEqrLtgnlP8/image.webp?st=2022-08-28T14%3A39%3A56Z&se=2022-08-28T16%3A37%3A56Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/webp&skoid=15f0b47b-a152-4599-9e98-9cb4a58269f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2022-08-28T15%3A12%3A17Z&ske=2022-09-04T15%3A12%3A17Z&sks=b&skv=2021-08-06&sig=PiIFGStjpzdDY4V5WfRtdzAUJ6guFYb66pY0Wp8VDoc%3D"
    }]
    this.setState({ images });*/
    //console.time('response in');
    this.setState({ isBusy: true })
    let image;
    image = await api.getImage(this.state.text, this.context.state.appConfig.genType || 'stableai');
    console.log("image", image);
    /*if (image?.error) {
      this.context.showToast(image.error, "error");
      this.setState({ isBusy: false })
      return;
    }
    if (!image || !image?.result?.data?.length) {
      this.context.showToast("Error generating image", "error");
      this.setState({ isBusy: false })
      return;
    }*/
    if (image instanceof Error) {
      this.context.showToast(image.message, "error");
      this.setState({ isBusy: false })
      return;
    }

    if (!image || !image?.length) {
      this.context.showToast("Error generating image", "error");
      this.setState({ isBusy: false })
      return;
    }
    mana--;
    mana = Math.max(mana, 0);
    this.context.updateAccount({ mana });
    if (image instanceof Error) {
      this.context.showToast(image.message, "error");
      this.setState({ isBusy: false })
      return;
    }
    //console.timeEnd('response in');
    /*const images = image.result.data.map((item: any, i: number) => {
      return { id: i, i: item.generation.image_path }
    })*/
    ;
    const images = image.map((item: any, i: number) => {
      return { id: i, i: item }
    });
    console.log("image", JSON.stringify(images));
    this.setState({ images, isBusy: false })
  }

  setMemeImage(image: any) {
    const imageUrl = image;

    let meme = { ...this.context.state.currentMeme };
    meme.imageUrl = imageUrl;
    this.context.setCurrentMeme(meme);
    //this.setState({ imageUrl: imageUrl, isBusy: false })
    this.setState({ isBusy: false })

    SheetManager.hide('edit_img_sheet');
    if (imageUrl) {
      let cancel: CancelPromise;
      const sideEffect = async (): Promise<void> => {
        try {
          const operation = this.getImageSize(imageUrl);
          cancel = operation.cancel;
          const imageSize = await operation.start();
          this.props.setImageSize(imageSize.width, imageSize.height);
        } catch (error) {
          if (__DEV__) console.warn(error);
        }
      }
      sideEffect();
      return () => {
        if (cancel) {
          cancel();
        }
      }

    }
  }

  onImageLoaded() {

  }

  render() {
    const renderItem = ({ item }: any) => {
      return <TouchableOpacity style={{ margin: 5, marginBottom: 0 }} onPress={() => {
        this.setMemeImage(item.i)
      }}>
        <Image style={{
          width: 150,
          height: 150,
          borderRadius: colors.borderRadius
        }} resizeMethod={'auto'} source={{ uri: item.i }}></Image>
      </TouchableOpacity>

    }
    return (
      <View style={[this.props.style, { flex: 1 }]}>
        <TouchableOpacity style={style.fullSize} onPress={this.edit.bind(this)}>
          {this.context.state.currentMeme.imageUrl ?
            <Image resizeMethod='auto' ref={this.image} style={style.fullSize} onLoadEnd={this.onImageLoaded.bind(this)} source={{ uri: this.context.state.currentMeme.imageUrl }}/> :
            <View style={[style.fullSize, style.hcenter, style.vcenter, { backgroundColor: "#e5e5e5" }]}>
              <Feather name="image" size={40} color="black"/>
              <Text style={{ color: colors.textColor }}>Tap to select image</Text>
            </View>
          }
        </TouchableOpacity>
        <ActionSheet keyboardShouldPersistTaps={'handled'} closeOnTouchBackdrop={!this.state.isBusy} containerStyle={{
          maxWidth: colors.maxWidth,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="edit_img_sheet">
          {!this.state.images.length ? <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <TextInput autoCorrect={true} blurOnSubmit={true} maxLength={140}
                       enablesReturnKeyAutomatically={true} onSubmitEditing={() => {
              Keyboard.dismiss();
              this.loadMagicPhoto();
            }} placeholder='A horse riding on the Moon' multiline={true} numberOfLines={3} onChangeText={(text: string) => {
              this.setState({ text })
            }} style={[style.fullWidth, style.textInput, {
              height: 100,
              marginBottom: 15,

            }]}></TextInput>

            <PrimaryButton progress={true} align={'left'} isBusy={this.state.isBusy} onPress={this.loadMagicPhoto.bind(this)} icon={
              <SimpleLineIcons style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} name='magic-wand'></SimpleLineIcons>} label={'Get Magic Image'} style={{
              width: '100%',
              marginBottom: 10,
              marginTop: 10
            }}/>


          </View> : <View style={[style.fullWidth, {
            padding: 10,
            height: Math.ceil(this.state.images.length / 2) * 200,
            flexDirection: 'column',
            display: 'flex'
          }]}>
            <FlatList ref={(ref) => {

            }} horizontal={false} contentContainerStyle={{}} data={this.state.images} style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              flex: 1,
            }} renderItem={renderItem} keyExtractor={(item) => item.id}
            />

            <PrimaryButton align={'left'} onPress={() => {
              this.setState({ images: [] })
            }} icon={
              <MaterialIcons name="delete-outline" size={20} color={colors.iconColorWhite}/>} label={'Clear'} style={{
              width: '100%',
              marginBottom: 10,
              marginTop: 10
            }}/>
          </View>

          }

        </ActionSheet>
      </View>
    )
  };

}

export default MemeImg;
