import React, { Component } from 'react';
import navigationService from '../services/navigation-service';
import { User, UserMeme, UserMemeComments } from '../models';
import { IMeme } from '../models/meme';
import api from '../services/api';
import { Alert, BackHandler, Linking, Platform, Share } from "react-native";
import ReactGA from "react-ga4";
import analytics from '@react-native-firebase/analytics';
import { Auth } from "aws-amplify";
import { InAppBrowser } from "react-native-inappbrowser-reborn";
import { Feather, MaterialIcons } from "@expo/vector-icons";
import { ToastOptions } from "react-native-toast-notifications/lib/typescript/toast";
import InAppReview from 'react-native-in-app-review';
import { activateAdapty, adapty, AdaptyError } from "react-native-adapty";
import { AsyncStorage } from 'react-native';
import { SheetManager } from "react-native-actions-sheet";
import checkVersion from 'react-native-store-version';
import DeviceInfo from "react-native-device-info";
///@ts-ignore

//let ReactGA: any;
if (Platform.OS === "web") {
  // ReactGA = require("react-ga4");
  ReactGA.initialize("G-DJ22RDECKF");
}

export interface IAppState {
  hasShownPolicy: string;
  reportUser: any;
  adaptyProducts: any[];
  os: 'android' | 'ios' | 'undefined';
  singedCognitoUserName: string,
  publicMemesList: { nextToken?: string, items: any[], loading: boolean }
  commentsMemesList: { nextToken?: string, items: any[], loading: boolean }
  myMemesList: { nextToken?: string, items: any[], loading: boolean }
  myLikedMemesList: { nextToken?: string, items: any[], loading: boolean }
  memesLiked: string[]
  showCommentBox: boolean,
  showMemeModal: boolean,
  showBuyModal: boolean,
  appConfig: any,
  currentMeme: IMeme,
  viewMeme: UserMeme,
  commentMemeId: string,
  screen: string,
  routeParams: any,
  user: User,
  isBusy: boolean,
  imageWidth: number,
  imageHeight: number,

}

export interface IContext {
  state: IAppState,
  getListItems: (stateKey: any, query: any, queryString: string, filter: any, refresh: boolean) => void,
  setCurrentMeme: (meme: IMeme, viewMeme?: UserMeme, callback?: any) => void,
  getViewMeme: (memeId: string) => void,
  buyProduct: (product: any) => void,
  postComment: (meme: UserMeme, comment: string) => void,
  likeMeme: (meme: UserMeme) => void,
  reportMeme: (meme: UserMeme) => void,
  openReportUser: (user?: User) => void,
  reportUserAccount: () => void,
  reportComment: (userComment: UserMemeComments) => void,
  saveMeme: () => void,
  sendFeedback: (subject: string, message: string) => void,
  updateAccount: (data: any) => void,
  registerEvent: (event: string) => void,
  shareMeme: (meme: UserMeme) => void,
  updateMeme: () => void,
  gotoStore: () => void,
  deleteUser: () => void,
  signOut: () => void,
  setAppProp: (obj: any, callback?: any) => void,
  showToast: (message: string, type: 'error' | 'success') => void,
  setScreen: (screen: string, navigate?: boolean, params?: any, callback?: any) => void
  setImageSize: (width: number, height: number) => void
  setUser: (user: any) => void
}

const initialState: any = {

  publicMemesList: { items: [], loading: false, nextToken: undefined },
  commentsMemesList: { items: [], loading: false, nextToken: undefined },
  myMemesList: { items: [], loading: false, nextToken: undefined },
  myLikedMemesList: { items: [], loading: false, nextToken: undefined },
  memesLiked: [],
  publicMemesToken: null,
  publicMemes: [],
  currentMeme: { imageUrl: '', layout: '1TextAbove', text1: 'Tap to add text', text2: 'Tap to add text' },
  screen: 'Home',
  appConfig: {},
  isBusy: false
}

export const AppContext = React.createContext<IContext>(initialState);
export const AppContextProvider = AppContext.Provider

export class AppProvider extends Component {
  state = initialState

  constructor(props: any) {
    super(props);
    this.getAppConfig();

  }

  registerEvent = (event: string) => {
    //Analytics.record({ name: `${Platform.OS === "web" ? "Web" : "Mobile"} - ${event}` });
    const eventName = (`${Platform.OS.toString()}_${event}`).replace(/ /gi, '_');
    if (Platform.OS === "web") {
      ReactGA.event({ category: "", action: eventName });

    } else {
      analytics().logEvent(eventName);
    }

    /*ReactGA.event({
      category: Platform.OS === "web" ? "Web" : "Mobile",
      action: event,
      value: 1,
      nonInteraction: true,
      transport: "xhr",
    });*/
  }

  async componentDidMount() {

    let os = "undefined";
    if (Platform.OS !== "web") {
      try {
        await activateAdapty({ sdkKey: 'public_live_BkB3BvbQ.ctawSUQHWwu6SGuixZch' });
        const { products } = await adapty.paywalls.getPaywalls();
        this.setState({ adaptyProducts: products });
        console.log("products", products);

      } catch (error: any) {
        console.log("error prods", error);

      }
      return;
    }
    if (navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
      os = "android";
    } else if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
      os = "ios";
    } else {

    }
    this.setState({ os: os });
    /* setTimeout(() => {
       this.setState({ hasShownPolicy });
     }, 3000);*/
    //this.showToast("Welcome to Meme Maker!", "success");
  }

  getAppConfig = async () => {
    const appConfig = await api.getAppConfig();

    //console.log("appConfig", appConfig)
    let hasShownPolicy = Platform.OS !== "web" ? (await AsyncStorage.getItem("hasShownPolicy")) : await localStorage.getItem("hasShownPolicy");
    hasShownPolicy = hasShownPolicy ? 'true' : 'false';
    //console.log("hasShownPolicy", hasShownPolicy)
    this.setState({ appConfig });
    setTimeout(() => {
      this.setState({ hasShownPolicy });
    }, 500);
    console.log("appConfig.allowUpdate", appConfig.allowUpdate)
    if (appConfig.allowUpdate && Platform.OS !== "web") {

      try {
        const check = await checkVersion({
          version: DeviceInfo.getVersion(), // app local version
          iosStoreURL: appConfig.iOSApp,
          androidStoreURL: appConfig.androidApp,

        });
        console.log("check", check)
        console.log("DeviceInfo.getVersion()", DeviceInfo.getVersion())
        if (check.result === 'new') {
          Alert.alert(
            'Update Needed',
            `Hey, there''s a new version in town, please update for the best performance!`,
            [
              { text: 'Cancel', onPress: () => console.log('Cancel Pressed'), style: 'cancel' },
              {
                text: 'Update',
                onPress: () => Linking.openURL(Platform.OS === 'android' ? appConfig.androidApp : appConfig.iOSApp)
              },
            ],
            { cancelable: false }
          )
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  setAppProp = (obj: any, callback?: any) => {
    let appConfig = { ...this.state };
    appConfig = { ...appConfig, ...obj };
    this.setState(appConfig, () => {
      if (callback) {
        callback();
      }
    });
  }
  openReportUser = (user?: User) => {
    if (!user) return;
    if (!this.state.user) {
      this.setScreen('Sign In', true);
      return;
    }
    if (user.id === this.state.user.id) {
      //this.showToast("You can't report yourself", "error");
      return;
    }

    this.setState({ reportUser: user, showMemeModal: false }, () => {
      SheetManager.show('user_menu_report')
    });
  }
  buyProduct = async (prod: any) => {
    /*const purchaserInfo = {
      "accessLevels": {},
      "nonSubscriptions": {
        "premium_basic": [
          {}
        ]
      },
      "profileId": "ee5b946c-244d-4b0b-9deb-44abc3cba8e0",
      "subscriptions": {}
    }
    console.log("purchaserInfo", Object.keys(purchaserInfo.nonSubscriptions)[0] === prod.prodId)*/
    console.log("prod", this.state.adaptyProducts)
    const adaptyProduct = this.state.adaptyProducts.find((p: any) => p.vendorProductId === prod.prodId);
    console.log("adaptyProdutc", adaptyProduct);
    try {
      const {
        receipt,
        purchaserInfo,
        product
      }: any = await adapty.purchases.makePurchase(adaptyProduct);
      console.log("receipt", receipt);
      console.log("purchaserInfo", JSON.stringify(purchaserInfo));
      console.log("product", JSON.stringify(product));
      if (Object.keys(purchaserInfo?.nonSubscriptions)[0] === prod.prodId) {
        this.setState({ isBusy: true });
        const purchasedMana = prod.mana;
        let { mana, boughtMana } = this.state.user;
        boughtMana = mana + purchasedMana;
        mana = mana + purchasedMana;
        await this.updateAccount({ mana, boughtMana });
        this.setState({ isBusy: false });
        this.showToast("You have successfully purchased " + purchasedMana + " mana", "success");
      }
    } catch (error: any) {
      console.log("error", error);
      this.showToast(error.localizedDescription, "error");
    }
    /*this.setState({ isBusy: true });
    const purchasedMana = prod.mana;
    let { mana, boughtMana } = this.state.user;
    boughtMana = mana + purchasedMana;
    mana = mana + purchasedMana;
    await this.updateAccount({ mana, boughtMana });
    this.setState({ isBusy: false });
    this.showToast("You have successfully purchased " + purchasedMana + " mana", "success");*/
  }
  setScreen = (screen: string, navigate?: boolean, params?: any, callback?: any) => {
    //console.log("setScreen", screen, navigate, params)
    /* if (screen === "Home") {
       this.setState({
         viewMeme: null,
         currentMeme: { imageUrl: '', layout: '1TextAbove', text1: 'Tap to add text', text2: 'Tap to add text' }
       })

     }*/
    if (screen === "Builder") {
      this.setState({
        viewMeme: null,
        currentMeme: { imageUrl: '', layout: '1TextAbove', text1: 'Tap to add text', text2: 'Tap to add text' }
      })
    }
    this.registerEvent("set screen " + screen?.toLowerCase());
    this.setState({
      screen, routeParams: params
    }, () => {
      if (navigate) navigationService.navigate(screen, params)
      if (callback) {
        callback();
      }

    })
  };

  setCurrentMeme = (meme: IMeme, viewMeme?: UserMeme, callbacl?: any) => {
    console.log("setCurrentMeme", meme)
    if (viewMeme) {
      this.setState({
        viewMeme,
        currentMeme: meme
      }, callbacl ? callbacl : null)
    } else {
      this.setState({

        currentMeme: meme
      }, callbacl ? callbacl : null)
    }

  }
  sendFeedback = async (subject: string, message: string) => {

    let result: any;
    result = await api.sendMessage(this.state.user.email, subject, message);
    //console.log("sendFeedback", result);
    if (result instanceof Error) {
      toast.show(result.message, { type: 'error' });
    } else {
      this.showToast("Feedback sent", "success");
    }
  }

  setImageSize(width
                 :
                 number, height
                 :
                 number
  ) {
    //console.log("setImageSize", width, height)
    this.setState({ imageWidth: width, imageHeight: height })
  }

  gotoStore() {
    if (this.state.os === 'ios') {
      window.open(this.state.appConfig.iOSApp)

    } else {
      window.open(this.state.appConfig.androidApp)
    }
  }

  getViewMeme = async (memeId: string) => {
    //console.log("getViewMeme", memeId)
    this.setState({ isBusy: true })
    const meme = await api.getMeme(memeId);
    console.log("getViewMeme", meme)
    this.setState({ viewMeme: meme, isBusy: false })
  }
  shareMeme = async (meme: UserMeme) => {
    this.registerEvent("share meme");
    console.log("shareMeme", `https://preview.imagenie.io/p/?m=${meme.id}`)
    const shareUrl = `https://preview.imagenie.io/p/?m=${meme.id}`;
    await Share.share({
      title: meme.title,
      message: shareUrl,
      url: shareUrl
    });
  }
  updateAccount = async (date: any) => {
    //this.registerEvent("update account");
    const user = await api.updateAccount(this.state.user.id, date);
    this.setState({ user })
  }
  deleteUser = async () => {
    this.registerEvent("delete user");
    await api.deleteUserMe(this.state.user.id, this.state.singedCognitoUserName);
    this.showToast("Your account has been deleted", "success");
    this.signOut();

    this.setScreen("Home");

  }

  setURLOpener() {
    if (Platform.OS === 'web') {
      return;
    }
    const opener = (url: string) => {
      //Browser.open({ url })
      InAppBrowser.open(url)
    };
    const config: any = Auth.configure(null);
    config.oauth.urlOpener = opener;
    Auth.configure(config);
  }

  signOut = async () => {
    this.setUser(null);
    this.setScreen('Home', true);
    this.setURLOpener();
    await Auth.signOut({});
    InAppBrowser.close();
  }
  setUser = async (user: any) => {
    if (this.state.screen !== "Meme") this.setScreen('Home', true)
    console.log("user", user)
    if (user) {
      const account = {
        id: user.attributes.sub,
        mana: 3,
        boughtMana: 3,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        picture: user.username.indexOf('facebook') !== -1 ? JSON.parse(user.attributes['picture']).data.url : user.attributes.picture,
        email: user.attributes.email
      };
      let acc
      acc = await api.getAccount(account.id)
      if (!acc) {
        acc = await api.createAccount(account);
      }
      /*if (user.username.indexOf('facebook') !== -1) {
        user.attributes['picture'] = JSON.parse(user.attributes['picture']).data.url;
      }*/
      this.setState({ user: acc, singedCognitoUserName: user.username })
      try {
        InAppBrowser.close();
      } catch (e) {

      }

    } else {
      this.setState({
        user: null,
        myMemes: [],
        myMemesToken: null,
        myMemesLoading: false,
        viewMeme: null,
        currentMeme: { imageUrl: '', layout: '1TextAbove', text1: 'Tap to add text', text2: 'Tap to add text' }
      })
    }

  }
  error = (e: any) => {
    this.setState({ isBusy: false })
    this.showToast(e.message, 'error')
  }
  saveMeme = async () => {
    try {
      const viewMeme = await api.saveMeme(this.state.currentMeme, this.state.user.id, this.error);
      let memeNumCreatedCount = (this.state.user.memeNumCreatedCount || 0) + 1;
      const user = await api.updateAccount(this.state.user.id, { memeNumCreatedCount });
      if (memeNumCreatedCount >= this.state.appConfig?.maxRateCreate) {
        this.requestReview();
      }
      this.setState({ viewMeme, user });
    } catch (e) {
      this.showToast("Error saving meme " + e.message, "error")
    }

  }

  requestReview() {
    if (InAppReview.isAvailable()) {
      InAppReview.RequestInAppReview()
        .then((hasFlowFinishedSuccessfully) => {
          console.log(
            'InAppReview in ios has launched successfully',
            hasFlowFinishedSuccessfully,
          );

          if (hasFlowFinishedSuccessfully) {

          }

        })
        .catch((error: any) => {

          console.log(error);
        });
    }
  }

  updateMeme = async () => {
    const viewMeme = await api.updateMeme(this.state.currentMeme, this.state.viewMeme, this.state.user.id);
    this.setState({ viewMeme });
  }

  getListItems = async (stateKey: any, query: any, queryString: string, filter: any, refresh: boolean) => {
    const obj = { ...this.state[stateKey] }
    //console.log("obj", obj)
    if (!refresh && !obj.nextToken) return;
    obj.loading = true;
    if (refresh) {
      obj.items = [];
    }
    this.setState({ [stateKey]: { ...obj } });
    const items = await api.getPListItems(query, queryString, filter, refresh ? null : obj.nextToken)
    obj.items = refresh ? items.items : obj.items.concat(items.items)
    obj.items = obj.items.filter((item: any) => {
      return item.reports ? item.reports.length < 5 : true
    })
    obj.nextToken = items.nextToken
    //console.log("nextToken", obj.nextToken)
    obj.loading = false;
    this.setState({ [stateKey]: obj });

  }

  postComment = async (meme: UserMeme, comment: string) => {
    await api.postComment(meme, this.state.user.id, comment, this.error);

  }
  likeMeme = async (meme: UserMeme) => {
    const memesLiked = (this.state.memesLiked || []).concat(meme.id)
    this.setState({ memesLiked })
    await api.likeMeme(meme, this.state.user.id, this.error, () => {
      //this.showToast("Meme liked", "success")
      /*const memesLiked = (this.state.memesLiked || []).concat(meme.id)
      this.setState({ memesLiked })*/
    });

  }
  reportMeme = async (meme: UserMeme) => {

    await api.reportMeme(meme, this.state.user.id, this.error, () => {
      this.showToast("Reported meme as inappropriate", "success")
    });

  }
  reportUserAccount = async () => {
    await api.reportUser(this.state.reportUser, this.state.user.id, this.error, () => {
      this.showToast("Reported user as inappropriate", "success")
    });

  }
  reportComment = async (userComment: UserMemeComments) => {

    await api.reportComment(userComment, this.state.user.id, this.error, () => {
      this.showToast("Reported comment as inappropriate", "success")
    });

  }

  showToast = (message: string, type: 'error' | 'success') => {
    let tp = type.toString();
    if (tp === 'error') {
      tp = 'danger';
    }
    const options: any = {
      placement: 'top',
      type: tp,
      duration: 3000,
      animationType: 'slide-in',
      animationDuration: 250,
      successColor: "#17ad3f",
      dangerColor: "#ad2b1a",
      warningColor: "orange",
      normalColor: "gray",
      successIcon: <Feather name="check" size={24} color="#ffffff"/>,
      dangerIcon: <MaterialIcons name="error-outline" size={24} color="#ffffff"/>,
      textStyle: { fontSize: 15, color: "#ffffff" },
      swipeEnabled: true
    }
    toast.show(message, options);
    //toast.show("Show custom toast", { data: { title: 'Toast title' } })
  }

  render() {
    return (
      <AppContext.Provider value={
        {
          state: this.state,
          showToast: this.showToast,
          getListItems: this.getListItems,
          sendFeedback: this.sendFeedback,
          updateAccount: this.updateAccount,
          postComment: this.postComment,
          deleteUser: this.deleteUser,
          gotoStore: this.gotoStore,
          reportMeme: this.reportMeme,
          reportUserAccount: this.reportUserAccount,
          reportComment: this.reportComment,
          signOut: this.signOut,
          shareMeme: this.shareMeme,
          likeMeme: this.likeMeme,
          saveMeme: this.saveMeme,
          updateMeme: this.updateMeme,
          setAppProp: this.setAppProp,
          openReportUser: this.openReportUser,
          setScreen: this.setScreen,
          buyProduct: this.buyProduct,
          registerEvent: this.registerEvent,
          getViewMeme: this.getViewMeme,
          setCurrentMeme: this.setCurrentMeme,
          setUser: this.setUser,
          setImageSize: this.setImageSize,

        }}>
        {this.props.children}
      </AppContext.Provider>)
  }
}
