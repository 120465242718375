import { AntDesign, Entypo, FontAwesome, FontAwesome5, Ionicons, MaterialIcons } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  FlatList,
  Platform,
  RefreshControl,
  AsyncStorage, Image, Modal, BackHandler, ScrollView, TextInput
} from 'react-native';
import config from './../aws-exports';

import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import ReceiveSharingIntent from 'react-native-receive-sharing-intent';
import navigationService from './../services/navigation-service';
import { AppContext, IContext } from '../store/app-provider';
///@ts-ignore
import { S3Image } from 'aws-amplify-react-native';
import MemeItem from "../components/meme-item";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import { UserMeme } from "../models";
import CommentBox from "../components/comment-box";
import CommentsModal from "../components/comments-modal";
import ItemsList from "../components/items-list";
import * as queries from "../graphql/queries";
import Alert from "../components/alert";
import PrimaryButton from "../components/primary-button";

interface Props {

}

interface State {
  isBusyFeedback: boolean;

  feedbackMessage: string,
  feedbackSubject: string

}

class HomeScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = { feedbackMessage: '', feedbackSubject: '', isBusyFeedback: false }
  }

  async componentDidMount() {
    /* const backAction = () => {
       this.context.setAppProp({ showCommentBox: false })
       console.log("back")
       /!*if (!navigationService.props.setHome) {
         //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen('Home', {}, false));
         navigationService.resetRoutes();
         navigationService.props.setHome = true;
         BackHandler.removeEventListener('hardwareBackPress', backAction);
         return true;
       }*!/
       return true;
     }
     BackHandler.addEventListener('hardwareBackPress', backAction);*/
    navigationService.props.setHome = true;
    //this.getIntent();
    //this.context.getPublicMemes(true);
  }

  componentDidUpdate(prevPops: any) {

  }

  gotoBuilder() {
    if (Platform.OS === "web") {
      SheetManager.show('download_sheet_home');
      return;
    }
    if (!this.context.state.user) {
      SheetManager.show('sign_sheet_home');
      return;
    }

    /* if (!this.context.state.user) {
       this.context.setScreen('Sign In', true);
       return;
     }*/

    this.context.setScreen('Builder', true);
  }

  gotoMeme(item: any) {
    this.context.registerEvent("view meme");
    this.context.setAppProp({
      showMemeModal: true,
      viewMeme: item
    })
    // this.context.setScreen('Meme', true, { post: item.id });

  }

  agreePolicy() {
    this.context.setAppProp({ hasShownPolicy: true });
    if (Platform.OS === "web") {
      localStorage.setItem("hasShownPolicy", "true");
    } else {
      AsyncStorage.setItem("hasShownPolicy", "true");
    }
  }

  getIntent() {

    if (Platform.OS === 'web') return;
    ReceiveSharingIntent.getReceivedFiles((files: any) => {
        // files returns as JSON Array example
        //console.log("fileus", files)
        const link = files[0]?.weblink || "";

        //ReceiveSharingIntent.clearReceivedFiles();
        //[{ filePath: null, text: null, weblink: null, mimeType: null, contentUri: null, fileName: null, extension: null }]
      },
      (error: any) => {
        console.log(error);
      },
      'com.can.imagenie' // share url protocol (must be unique to your app, suggest using your apple bundle id)
    );

  }

  reportUser() {
  }

  render() {
    const renderItem = ({ item }: any) => {
      return item.user ? <MemeItem item={item} onPress={this.gotoMeme.bind(this)}/> : null

    }
    const busyIndicator = () => {
      /* if (this.props.app.isBusy) {
          return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
      } */
    }

    return (
      <View style={style.page}>
        <View style={[style.container]}>
          <ItemsList query={queries.getPostsByTime} stateKey={'publicMemesList'} queryString={'getPostsByTime'} filter={{
            type: "public",
            limit: 10,
            sortDirection: 'DESC'
          }} renderItem={renderItem} stateItem={this.context.state.publicMemesList}/>


        </View>
        <TouchableOpacity onPress={this.gotoBuilder.bind(this)}
                          style={[{
                            borderWidth: 1,
                            borderColor: colors.grey,
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 50,
                            position: 'absolute',
                            bottom: 15,
                            right: 15,
                            height: 50,
                            backgroundColor: '#fff',
                            borderRadius: 100,
                          }, style.shadowAddButton]}
        >

          <Ionicons name="add" size={30} color={colors.primaryColor}/>

        </TouchableOpacity>
        {/* <CommentsModal meme={this.context.state.viewMeme}/>*/}
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="download_sheet_home">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={this.context.state.os === "android" ?
              <Entypo name="google-play" size={24} color={colors.iconColorWhite}/> :
              <FontAwesome5 name="app-store" size={24} color={colors.iconColorWhite}/>} style={{ width: '100%' }} onAccept={() => {
              this.context.gotoStore();
              SheetManager.hide('download_sheet_home')
            }} message={"You can create new memes only in the mobile app available on Google Play and AppStore!"} acceptLabel={"Download"}/>
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="sign_sheet_home">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={
              <FontAwesome5 name="user" size={24} color={colors.iconColorWhite}/>} style={{ width: '100%' }} onAccept={() => {
              this.context.setScreen('Sign In', true);
              SheetManager.hide('sign_sheet_home')
            }} message={"You need to be signed in to be able to create a new meme!"} acceptLabel={"Sign In"}/>
          </View>

        </ActionSheet>

        {this.context.state.appConfig?.privacy && this.context.state.hasShownPolicy === 'false' && Platform.OS !== 'web' ?
          <Modal
            animationType="fade" style={{
            display: "flex",
            bottom: 0,
            top: 0,
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}

            transparent={true}
            visible={this.context.state.appConfig?.privacy && this.context.state.hasShownPolicy === 'false'}

          >
            <View style={[style.fullWidth, style.column, {
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.5)',
              padding: 0,
            }]}>

              <View style={[{
                height: '100%',
                width: '100%',
                maxWidth: colors.maxWidth,
                padding: 20,
                borderRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                paddingTop: 0,
                backgroundColor: "#fff"
              }]}>
                <ScrollView style={[style.fullWidth, { padding: 20, flex: 1 }]}>
                  <Text style={[style.fullSize, { color: colors.textColor }]}>
                    {this.context.state.appConfig?.privacy?.replace(/<br\/>/gi, '\n') + '\n\n'}
                    {this.context.state.appConfig?.terms?.replace(/<br\/>/gi, '\n')}
                  </Text>
                </ScrollView>
                <PrimaryButton align={'left'} onPress={this.agreePolicy.bind(this)}
                               label={'Accept and Continue'} style={{ width: '100%', marginTop: 10 }}/>
              </View>

            </View>
          </Modal> : null}
      </View>
    )

  }
}

export default (withTranslation()(HomeScreen));

const styles = StyleSheet.create({
  input: {
    padding: 5,
    height: 35,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: colors.textColor,
    color: colors.textColor
  },
  screenItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 10,
    width: '100%',
    height: '100%',
    backgroundColor: colors.textColor
  }
});
