import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  RefreshControl,
  FlatList,
  TextInput, ActivityIndicator
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import MemeItem from "./meme-item";
import { UserMeme, UserMemeComments } from "../models";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { Keyboard } from 'react-native';
import ItemsList from "./items-list";
import * as queries from "../graphql/queries";
import UserButton from "./user-button";

interface State {
  isBusy?: boolean,
  comment?: string
}

interface Props {
  meme: UserMeme,
  style?: ViewStyle
}

class CommentBox extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  commentsRef: any

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  async commentPost() {
    Keyboard.dismiss();
    if (!this.state.comment) {
      return;
    }
    if (!this.context.state.user) {
      this.context.setScreen('Sign In', true);
      return;
    }
    this.setState({ isBusy: true });
    await this.context.postComment(this.props.meme, this.state.comment || "")
    this.commentsRef.getListItems(true);
    this.setState({ isBusy: false, comment: '' });
    Keyboard.dismiss();
    //this.context.getMemeComments(this.props.meme.id, true);
  }

  reportComment(comment: UserMemeComments) {
    if (!this.context.state.user) {
      this.context.setScreen('Sign In', true);
      return;
    }
    this.context.reportComment(comment);
  }

  componentDidMount() {

  }

  openUserMenu(user: any) {
    this.context.setAppProp({
      showCommentBox: false,
    })
    this.context.openReportUser(user);
  }

  render() {
    const busyIndicator = () => {
      if (this.state.isBusy) {
        return <ActivityIndicator size="small" style={style.busyIndicator} color={colors.busyIndicator}/>
      }
    }
    const renderItem = ({ item }: any) => {
      return item.user ?
        <View style={[style.row, { marginTop: 10, marginBottom: 10 }]}>

          <UserButton hideBorder={true} user={item.user} onPress={this.openUserMenu.bind(this, item.user)} size={25}></UserButton>
          <View style={{
            flex: 1,
            backgroundColor: colors.grey,
            borderRadius: colors.borderRadius,
            padding: 20,
            marginLeft: 10
          }}>
            <Text>{item.comment}</Text>
            <TouchableOpacity onPress={this.reportComment.bind(this, item)}>
              <AntDesign style={{
                marginTop: 10,

              }} name="flag" size={14} color={colors.iconColor}/>
            </TouchableOpacity>
          </View>

        </View> : null;

    }
    return (
      <View style={[this.props.style, style.vcenter]}>

        <ItemsList ref={(ref) => {
          this.commentsRef = ref
        }} query={queries.getCommentsByMemeAndTime} stateKey={'commentsMemesList'} queryString={'getCommentsByMemeAndTime'} filter={{
          memeId: this.props.meme.id,
          limit: 10,
          sortDirection: 'DESC'
        }} renderItem={renderItem} stateItem={this.context.state.commentsMemesList}/>
        <View style={[style.row, style.vcenter, style.fullWidth]}>
          <TextInput style={{
            flex: 1,
            height: 60,
            opacity: !this.context.state.user ? 0.5 : 1,
            borderRadius: colors.borderRadius,
            borderColor: colors.primaryColor,
            borderWidth: 0,
            backgroundColor: colors.grey,
            padding: 20
          }}
                     multiline={true}
                     contextMenuHidden={!this.context.state.user}
                     value={this.state.comment}
                     numberOfLines={3}
                     onChangeText={(text) => this.setState({ comment: text })}
          />
          <View style={[style.fullHeight, style.row, style.hcenter, style.vcenter, { width: 40 }]}>


            {this.state.isBusy ?
              <ActivityIndicator size="small" style={{ marginLeft: 10 }} color={colors.busyIndicator}/> :
              <TouchableOpacity disabled={!this.context.state.user} onPress={this.commentPost.bind(this)} style={{ marginLeft: 10 }}>
                <Ionicons style={{ opacity: !this.context.state.user ? 0.5 : 1 }} name="ios-send-sharp" size={24} color={colors.primaryColor}/>
              </TouchableOpacity>}
          </View>
        </View>

      </View>

    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default CommentBox;
