import {
  ActivityIndicator,
  Alert,
  AsyncStorage,
  BackHandler,
  Linking,
  StatusBar,
  TextInput,
  TouchableOpacity
} from 'react-native';
import { Animated, StyleSheet, Text, View, Platform, AppState, SafeAreaView } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ToastProvider, useToast } from 'react-native-toast-notifications'

import config from './src/aws-exports';

import Amplify from 'aws-amplify';
import "./src/services/i18n";
//global.Buffer = Buffer;
import React, { useContext, useEffect, useState } from 'react';
import ReceiveSharingIntent from 'react-native-receive-sharing-intent';
import navigationService from './src/services/navigation-service';
import { AppContext, AppProvider } from './src/store/app-provider';
import AppNavigator from './src/components/navigator';
import CommentsModal from "./src/components/comments-modal";
import MemeModal from "./src/components/meme-modal";
import { Buffer } from 'buffer';
import { AntDesign, Feather, FontAwesome, FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import BuyModal from "./src/components/buy-modal";
import style from "./src/constants/style";
import colors from "./src/constants/colors";
import { activateAdapty } from "react-native-adapty";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import PrimaryButton from "./src/components/primary-button";

global.Buffer = Buffer;
declare var global: any;
if (typeof global.crypto !== 'object') {
  global.crypto = {
    getRandomValues: (array: any[]) => array.map(() => Math.floor(Math.random() * 256)),
  };
}

//Auth.configure(config);

/*  class Welcome extends React.Component {
   render() {
       <AppProvider></AppProvider>
   }
 } */
//new StoreBridge();
export default function App() {
  const appContext = useContext(AppContext);
  const menu = [{ label: "My Memes", icon: "book", screen: "MyPosts" }, {
    label: "My Liked",
    icon: "heart",
    screen: "MyLiked"
  }, {
    label: "My Account",
    icon: "user-circle",
    screen: "MyAccount"
  }, {
    label: "Feedback",
    icon: "inbox",
    screen: "Feedback"
  }]
  const [feedbackMessage, setfeedbackMessage] = useState('');
  const [feedbackSubject, setffeedbackSubject] = useState('');
  const [isBusyFeedback, setisBusyFeedback] = useState(false);
  //console.log("hour", (new Date().getHours()))
  let listenForIntent: boolean;
  console.disableYellowBox = true;
  if (Platform.OS === 'web') {
    //console.log("window.location.href", window.location.href)
    config.oauth.redirectSignOut = config.oauth.redirectSignIn = window.location.href.indexOf("imagenie") !== -1 ? "https://www.imagenie.io/" : "http://localhost:19006/"
  } else {

    const backAction = () => {

      if (!navigationService.props.setHome) {
        //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen('Home', {}, false));
        navigationService.resetRoutes();
        navigationService.props.setHome = true;
        BackHandler.removeEventListener('hardwareBackPress', backAction);
        return true;
      }
      return true;
    }
    BackHandler.addEventListener('hardwareBackPress', backAction);
    /* const modalHide = () => {
       appContext?.setAppProp({ showCommentBox: false })

       return true;
     }
     BackHandler.addEventListener('hardwareBackPress', modalHide);*/

    StatusBar.setBarStyle('light-content', true)
    StatusBar.setBackgroundColor(colors.primaryColor);
    //StatusBar.setTranslucent(true);
    config.oauth.redirectSignOut = config.oauth.redirectSignIn = "imagenie://"
    //SplashScreen.hide();
  }

  const getIntent = () => {

    ReceiveSharingIntent.getReceivedFiles((files: any) => {
        // files returns as JSON Array example
        //console.log("fileus", files)
        const link = files[0]?.weblink || "";
        if (link.indexOf("youtube") !== -1 || link.indexOf("youtu.be") !== -1) {
          /*  const videoId = link.replace("https://youtu.be/", "").replace("https://www.youtube.com/", "").replace("watch?v=", "");
           (store.dispatch as ThunkDispatch<any, void, ActionTypes>)(gotoVideo({ id: { videoId } })); */
        }
        //ReceiveSharingIntent.clearReceivedFiles();

      },
      (error: any) => {
        console.log(error);
      },
      'com.can.imagenie' // share url protocol (must be unique to your app, suggest using your apple bundle id)
    );

  }
  /* API.configure(config);
  Amplify.configure(config); */
  /*  Amplify.configure({
       ...config,
       Analytics: {
           disabled: true,
       },
   }); */

  /*  Amplify.configure({
       // OPTIONAL - if your API requires authentication
       Auth: {
           // REQUIRED - Amazon Cognito Identity Pool ID
           identityPoolId: config.aws_cognito_identity_pool_id,
           // REQUIRED - Amazon Cognito Region
           region: config.aws_cognito_region,
           // OPTIONAL - Amazon Cognito User Pool ID
           userPoolId: config.aws_user_pools_id,
           // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
           userPoolWebClientId: config.aws_user_pools_web_client_id,
       },
       API: {
           endpoints: [
               {
                   name: config.aws_cloud_logic_custom[0].name,
                   endpoint: config.aws_cloud_logic_custom[0].endpoint,
                   region: config.aws_cloud_logic_custom[0].region,
               }
           ]
       }
   }); */
  Amplify.configure({
    ...config,
    Analytics: {
      disabled: true,
    },
  });
  /*  Auth.configure(config);
   Storage.configure(config); */
  /* API.configure({
      endpoints: [
          {
              name: config.aws_cloud_logic_custom[0].name,
              endpoint: config.aws_cloud_logic_custom[0].endpoint,
              region: config.aws_cloud_logic_custom[0].region,
          }
      ]
  }); */
  //API.configure(config);
  //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(getAppConfig());

  const signOut = (context: any) => {
    SheetManager.hide('user_menu');
    context.signOut();

  }
  const sendMessage = async (context: any) => {
    if (feedbackSubject && feedbackMessage) {

      setisBusyFeedback(true);
      await context.sendFeedback(feedbackSubject, feedbackMessage);
      setisBusyFeedback(false);
      setfeedbackMessage('');
      setffeedbackSubject('');
      SheetManager.hide(`feedback`);

    } else {
      toast.show("Please fill all the fields", { type: 'error' })
    }
  }

  const gotoMyScreen = (context: any, item: any) => {
    SheetManager.hide('user_menu');
    if (item.screen !== 'Feedback') {
      context.setScreen(item.screen, true);
    } else {
      setTimeout(() => {
        SheetManager.show(`feedback`);
        //this.context.showToast(`Feedback is not available on the mobile app`, 'success');
      }, 500);

    }

  }
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.primaryColor }}>
      <AppProvider>
        <ToastProvider>

          <AppNavigator></AppNavigator>

          <AppContext.Consumer>
            {context => <MemeModal meme={context.state.viewMeme}/>}
          </AppContext.Consumer>

          <AppContext.Consumer>
            {context => <BuyModal/>}
          </AppContext.Consumer>
          <AppContext.Consumer>
            {context => <View><ActionSheet id={`user_menu_report`}>
              <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 10 }]}>
                <PrimaryButton align={'left'} onPress={() => {
                  context.reportUserAccount();
                  SheetManager.hide('user_menu_report')
                }} icon={
                  <AntDesign style={{}} size={20} color={colors.primaryColor} name='flag'></AntDesign>} label={'Report User'} style={{
                  width: '100%',
                  marginTop: 10
                }}/>
              </View>
            </ActionSheet>
              <ActionSheet containerStyle={{
                maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderRadius: colors.borderRadius
              }} defaultOverlayOpacity={0.5} id={`feedback`}>
                <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20 }]}>
                  <TextInput placeholder='Feedback subject' multiline={true} numberOfLines={1} onChangeText={(text: string) => {

                    setffeedbackSubject(text);
                  }} style={[style.fullWidth, style.textInput, {

                    marginBottom: 15,

                  }]}></TextInput>
                  <TextInput placeholder='Feedback message' multiline={true} numberOfLines={3} onChangeText={(text: string) => {

                    setfeedbackMessage(text);
                  }} style={[style.fullWidth, style.textInput, {
                    height: 100,
                    marginBottom: 15,

                  }]}></TextInput>
                  <PrimaryButton isBusy={isBusyFeedback} align={'left'} onPress={() => {
                    sendMessage(context)
                  }} icon={
                    <FontAwesome style={{}} size={20} color={colors.iconColorWhite} name='send'></FontAwesome>} label={'Send'} style={{
                    width: '100%',

                  }}/>
                </View>
              </ActionSheet>
              <ActionSheet containerStyle={{
                maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderRadius: colors.borderRadius
              }} defaultOverlayOpacity={0.5} id={`user_menu`}>
                <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20 }]}>
                  {menu.map((item: any, index: any) => {
                    return <TouchableOpacity key={index} onPress={() => {
                      gotoMyScreen(context, item)
                    }} style={[style.fullWidth]}>
                      <View style={[style.row, style.fullWidth, { marginTop: 10, padding: 10 }]}>
                        <FontAwesome5 name={item.icon} size={24} style={{ marginTop: 0 }} color="black"/>
                        <Text style={{
                          marginLeft: 20,
                          flex: 1,
                          fontSize: 18,
                          fontWeight: 'bold'
                        }}>{item.label}</Text>
                      </View>
                    </TouchableOpacity>

                  })}

                  <PrimaryButton align={'right'} onPress={() => {
                    signOut(context)
                  }} icon={
                    <FontAwesome style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} name='sign-out'></FontAwesome>} label={'Sign Out'} style={{
                    width: '100%',
                    marginTop: 10, marginBottom: 10
                  }}/>
                </View>
              </ActionSheet>
            </View>}
          </AppContext.Consumer>
          <AppContext.Consumer>
            {context => <CommentsModal meme={context.state.viewMeme}/>}
          </AppContext.Consumer>
        </ToastProvider>

      </AppProvider>
    </SafeAreaView>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
