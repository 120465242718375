import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { FontAwesome5 } from "@expo/vector-icons";
import PrimaryButton from "./primary-button";

interface State {

}

interface Props {
  message: string
  onAccept?: any
  icon?: any
  acceptLabel: string | 'Ok'
  style?: ViewStyle
}

class Alert extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  render() {

    return (
      <View style={[this.props.style, { padding: 10 }]}>
        <Text style={{ width: '100%', fontSize: 20 }}>{this.props.message}</Text>
        <PrimaryButton align={'left'} icon={this.props.icon} onPress={() => {
          this.props.onAccept()
        }} label={this.props.acceptLabel} style={{
          width: '100%',
          marginTop: 30
        }}/>
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default Alert;
