import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { FontAwesome } from "@expo/vector-icons";
import { User } from "../models";

interface State {

}

interface Props {
  hideBorder?: boolean,
  user?: User | null
  style?: ViewStyle
  size: number
  onPress?: any
}

class UserButton extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  render() {

    return (
      <TouchableOpacity onPress={this.props.onPress} style={[style.userButton, style.hright, {
        width: this.props.size,
        height: this.props.size,
        backgroundColor: "#fff",
        borderWidth: this.props.hideBorder ? 0 : 2,
        borderColor: colors.iconColorWhite,
        borderRadius: this.props.size,
        justifyContent: "center",
        alignItems: 'center'
      }]}>
        {/* <View style={[style.userButton, style.hright, {
          width: this.props.hideBorder ? this.props.size : this.props.size - 6,
          height: this.props.hideBorder ? this.props.size : this.props.size - 6,
          borderRadius: this.props.hideBorder ? this.props.size : this.props.size - 6,
          justifyContent: "center",
          alignItems: 'center'
        }]}>*/}
        {this.props.user?.picture && this.props.user?.picture !== "-" && this.props.user?.picture?.indexOf('https') !== -1 ?
          <Image style={{ resizeMode: 'cover', width: '100%', height: '100%' }} source={{
            uri: this.props.user?.picture
          }}></Image> : this.props.user ? <Text style={{
              fontWeight: 'bold',
              color: colors.textColor,
              marginBottom: 0
            }}>{this.props.user?.firstName?.charAt(0) + this.props.user?.lastName?.charAt(0)}</Text> :
            <FontAwesome style={{ marginBottom: 0 }} size={this.props.size / 2} color={colors.textColor} name='user'></FontAwesome>}
        {/*</View>*/}

      </TouchableOpacity>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default UserButton;
