import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, TextInput } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import { TextStroke } from './text-stroke';
import { IMeme } from "../models/meme";

interface State {

}

interface Props {
  style?: ViewStyle
  onHeightChange?: any
  textStyle?: any
  textProp: 'text1' | 'text2'
}

class MemeTxt extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = { text: "" }

  }

  setText(text: string) {
    let meme = { ...this.context.state.currentMeme };

    meme[this.props.textProp] = text;

    this.context.setCurrentMeme(meme);
  }

  componentDidMount() {

  }

  edit() {
    SheetManager.show(`edit_text_sheet_${this.props.textProp}`);
  }

  render() {

    return (
      <View onLayout={(event) => {
        let { x, y, width, height } = event.nativeEvent.layout;
        this.props.onHeightChange(height);
      }} style={[this.props.style]}>

        <TouchableOpacity onPress={this.edit.bind(this)}>
          {this.context.state.currentMeme[this.props?.textProp] ?
            (this.context.state.currentMeme[this.props?.textProp] !== 'undefined' ?
              <Text style={this.props.textStyle}>{this.context.state.currentMeme[this.props.textProp]}</Text> : null) :
            <Text style={{ color: colors.textColor }}>Tap to add text</Text>}
        </TouchableOpacity>

        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id={`edit_text_sheet_${this.props.textProp}`}>
          <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20 }]}>
            <TextInput value={this.context.state.currentMeme[this.props?.textProp] === "Tap to add text" ? '' : this.context.state.currentMeme[this.props?.textProp]} ref={txt => {

            }} onSubmitEditing={() => {
              SheetManager.hideAll()
            }} placeholder='Enter text here' onChangeText={(text) => {
              this.setText(text)
            }} style={[style.fullWidth, style.textInput]}></TextInput>
          </View>
        </ActionSheet>
      </View>
    )
  };

}

const styles = StyleSheet.create({

  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default MemeTxt;
