import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import ItemsList from "../components/items-list";
import * as queries from "../graphql/my-queries";
import MemeItem from "../components/meme-item";
import CommentsModal from "../components/comments-modal";

interface Props {

}

interface State {

}

class MyPLikedScreen extends React.Component<Props, State> {

  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);

  }

  async componentDidMount() {

  }

  gotoMeme(item: any) {
    this.context.setAppProp({
      showMemeModal: true,
      viewMeme: item
    })
    // this.context.setScreen('Meme', true, { post: item.id });

  }

  componentDidUpdate(prevPops: any) {

  }

  render() {

    const renderItem = ({ item }: any) => {
      return (
        <MemeItem item={item.meme} onPress={this.gotoMeme.bind(this)}/>
      )
    }

    return (
      <View style={style.page}>
        <View style={[style.container]}>
          {this.context.state.user ?
            <ItemsList query={queries.getLikesByTimeAndUser} stateKey={'myLikedMemesList'} queryString={'getLikesByTimeAndUser'} filter={{
              userId: this.context.state.user.id,
              limit: 10,
              sortDirection: 'DESC'
            }} renderItem={renderItem} stateItem={this.context.state.myLikedMemesList}/> : null}

        </View>
        {/* <CommentsModal meme={this.context.state.viewMeme}/>*/}
      </View>

    )
  };

}

export default (withTranslation()(MyPLikedScreen));

const styles = StyleSheet.create({
  input: {
    padding: 5,
    height: 35,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: colors.textColor,
    color: colors.textColor
  },
  screenItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 10,
    width: '100%',
    height: '100%',
    backgroundColor: colors.textColor
  }
});
