import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Modal } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { UserMeme } from "../models";
import { FontAwesome5 } from "@expo/vector-icons";
import CommentBox from "./comment-box";
import MemeBar from "./meme-bar";
///@ts-ignore
import { S3Image } from 'aws-amplify-react-native';
import UserButton from "./user-button";
import CommentsModal from "./comments-modal";

interface State {

}

interface Props {
  meme: UserMeme
  style?: ViewStyle
}

class MemeModal extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  openUserMenu(user: any) {

    this.context.openReportUser(user);
  }

  render() {

    return (
      <View>
        {this.context.state.showMemeModal ? <Modal
          animationType="slide" style={{
          zIndex: 99,
          display: "flex",
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}

          transparent={true}
          visible={this.context.state.showMemeModal}
          onRequestClose={() => {
            this.context.setAppProp({
              showMemeModal: false,
            })
          }}
        >
          <View style={[style.fullSize, {
            backgroundColor: colors.grey,
            padding: 10
          }]}>
            <TouchableOpacity onPress={() => {
              this.context.setAppProp({ showMemeModal: false })
            }} style={{
              width: '100%',
              borderRadius: colors.borderRadius,
              marginBottom: 20,
              backgroundColor: 'transparent',
              height: 30,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <FontAwesome5 name="angle-down" size={20} color={colors.iconColor}/>
            </TouchableOpacity>
            {this.context.state.viewMeme ?
              <View style={[style.column, {
                alignItems: 'center',
                flex: 1,
                justifyContent: 'center',
                padding: 0
              }]}>
                <View style={[style.row, style.fullWidth, style.vcenter, {
                  padding: 0,
                  justifyContent: 'flex-start'
                }]}>

                  <UserButton onPress={this.openUserMenu.bind(this, this.context.state.viewMeme?.user)} user={this.context.state.viewMeme?.user} size={36}></UserButton>
                  <Text style={{
                    marginLeft: 10,
                    color: colors.textColor,
                    fontWeight: 'bold',
                    fontSize: 20,
                    marginTop: 10,
                    marginBottom: 10
                  }}>{this.context.state.viewMeme.title}</Text>
                </View>


                <S3Image theme={{
                  photo: {
                    resizeMode: "contain",

                    width: '100%',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'flex-start',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                  }
                }} resizeMode={"contain"} level="public" imgKey={this.context.state.viewMeme.memeImage}/>
                <MemeBar style={{
                  width: '100%',
                  maxWidth: colors.maxWidth,
                  marginTop: 10
                }} meme={this.context.state.viewMeme}/>
              </View> : null}
          </View>
          {/*<CommentsModal meme={this.context.state.viewMeme}/>*/}
        </Modal> : null}
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default MemeModal;
